import { App, defineAsyncComponent } from 'vue';

const FlagIcon = defineAsyncComponent(() => import('./language/FlagIcon.vue'));
const HelpMarker = defineAsyncComponent(() => import('./generic/HelpMarker.vue'));
const UserProfileImage = defineAsyncComponent(() => import('./user/UserProfileImage.vue'));
const UserInfo = defineAsyncComponent(() => import('./user/UserInfo.vue'));
const UserStatus = defineAsyncComponent(() => import('./user/UserStatus.vue'));
const Collapsible = defineAsyncComponent(() => import('./generic/Collapsible.vue'));
const InfoTile = defineAsyncComponent(() => import('./generic/InfoTile.vue'));

export default async function config(app: App): Promise<void> {
    app.component('FlagIcon', FlagIcon);
    app.component('HelpMarker', HelpMarker);
    app.component('UserProfileImage', UserProfileImage);
    app.component('UserInfo', UserInfo);
    app.component('UserStatus', UserStatus);
    app.component('Collapsible', Collapsible);
    app.component('InfoTile', InfoTile);
}
