import { Component, computed, ComputedRef, defineAsyncComponent, markRaw, ref } from 'vue';
import { TransitionType, TransitionDirection } from '../animation/animateComposable';
import Deferred, { IDeferred } from '@/core/async/Deferred';
import dictionary from '@/core/dictionary/dictionary';
import guidGenerator from '@/core/guid/guidGenerator.service';
import keyboardService from '@/core/util/keyboard.service';

const dialogComponent = defineAsyncComponent({ loader: () => import('./ModalConfirm.vue') });

export interface ModalOptions {
    size: 'auto' | 'lg' | 'md' | 'sm' | 'xs' | 'max' | 'full';
    enterAnimation?: TransitionType;
    enterDirection?: TransitionDirection;
    leaveDirection?: TransitionDirection;
    hideMinimizeButton?: boolean;
    hideCloseButton?: boolean;
    closeOnBackdrop?: boolean;
    closeOnEscape?: boolean;
}

export interface Modal {
    id: string;
    description?: string;
    zIndex: number;
    promise: IDeferred<any>;
    options: ModalOptions;
    component: Component;
    componentAttrs?: Record<string, any>;
    state: 'hidden' | 'entering' | 'visible';
    value?: any;
    contentChanged: boolean;
    minimized: boolean;
    lock: (state: boolean) => void;
    close: (checkForUnsavedChanges: boolean) => Promise<void>;
    minimize: () => void;
    maximize: () => void;
}

const defaultOptions: ModalOptions = {
    size: 'lg',
    enterAnimation: 'zoom',
    enterDirection: '',
    leaveDirection: '',
    closeOnBackdrop: false,
    closeOnEscape: true,
    hideMinimizeButton: true,
};

class ModalService {
    public dialogs = ref<Array<Modal>>([]);

    public get minimizedDialogs() {
        return computed(() => this.dialogs.value.filter(x => x.minimized));
    }

    public get dialogAnimationInProgress() {
        return computed(() => this.dialogs.value.some(x => x.state !== 'visible'));
    }

    public get activeModal(): ComputedRef<Modal | undefined> {
        return computed(() => {
            for (let i = this.dialogs.value.length - 1; i >= 0; i--) {
                if (!this.dialogs.value[i].minimized)
                    return this.dialogs.value[i];
            }

            return undefined;
        });
    }

    constructor() {
        document.addEventListener('keyup', (e) => {
            if (keyboardService.isEscape(e)) {
                const dialog = this.activeModal.value;
                if (dialog && dialog.options.closeOnEscape) {
                    e.preventDefault();
                    this.hideModal(dialog.id);
                }
            }
        });
    }

    public async showModal<T = any>({ description, component, componentAttrs }: { description?: string, component: Component, componentAttrs?: Record<string, any> }, options?: ModalOptions): Promise<T | undefined> {
        const id = guidGenerator.newGuid();
        const promise = new Deferred<any>();
        const zIndex = this.getNextZIndex();

        const self = this;

        this.dialogs.value.push({
            id: id,
            description: description,
            zIndex: zIndex,
            promise: promise,
            options: {
                ...defaultOptions,
                ...options,
            },
            component: markRaw(component),
            componentAttrs: componentAttrs,
            contentChanged: false,
            minimized: false,
            state: 'entering',
            lock: function(state) {
                this.options.hideCloseButton = state;
                this.options.closeOnBackdrop = !state;
                this.options.closeOnEscape = !state;
            },
            close: async function(checkForUnsavedChanges: boolean) {
                if (checkForUnsavedChanges && this.contentChanged) {
                    const result = await self.dialogConfirmation({
                        title: 'Du har ændringer som ikke er gemt',
                        okText: 'Afslut uden og gemme',
                        cancelText: 'Annuller',
                    });
    
                    if (!result) {
                        return;
                    }
                }

                await self.hideModal(this.id);
            },
            minimize: function() {
                this.minimized = true;
                self.handleBodyScrollLock();
            },
            maximize: function() {
                this.minimized = false;
                self.handleBodyScrollLock();
            },
        });

        this.handleBodyScrollLock();

        return promise.promise;
    }

    public async dialogConfirmation({ title, okText, cancelText, okOnly, component, componentAttrs }:
        { title: string, okText?: string, cancelText?: string, okOnly?: boolean, component?: Component, componentAttrs?: Record<string, any> }): Promise<boolean> {
        const id = guidGenerator.newGuid();
        const promise = new Deferred<boolean>();
        const zIndex = this.getNextZIndex();

        const self = this;

        this.dialogs.value.push({
            id: id,
            zIndex: zIndex,
            promise: promise,
            options: {
                ...defaultOptions,
                hideCloseButton: true,
                hideMinimizeButton: true,
                closeOnBackdrop: false,
                size: 'xs',
            },
            component: markRaw(dialogComponent),
            componentAttrs: {
                title,
                okText: okText ?? dictionary.get('Yes'),
                cancelText: cancelText ?? dictionary.get('No'),
                okOnly: okOnly,
                component: component ? markRaw(component) : undefined,
                componentAttrs: componentAttrs,
            },
            contentChanged: false,
            minimized: false,
            state: 'entering',
            lock: function(state) {
                this.options.hideCloseButton = state;
                this.options.closeOnBackdrop = !state;
                this.options.closeOnEscape = !state;
            },
            close: async function(checkForUnsavedChanges: boolean) {
                if (checkForUnsavedChanges && this.contentChanged) {
                    const result = await self.dialogConfirmation({
                        title: 'Du har ændringer som ikke er gemt',
                        okText: 'Afslut uden og gemme',
                        cancelText: 'Annuller',
                    });
    
                    if (!result) {
                        return;
                    }
                }

                await self.hideModal(this.id);
            },
            minimize: function() {
                this.minimized = true;
                self.handleBodyScrollLock();
            },
            maximize: function() {
                this.minimized = false;
                self.handleBodyScrollLock();
            },
        });

        this.handleBodyScrollLock();

        return promise.promise as Promise<boolean>;
    }

    public async hideModal(id: string, value?: any) {
        if (this.dialogAnimationInProgress.value) return;

        const modal = this.dialogs.value.find(x => x.id === id);
        if (modal && modal === this.activeModal.value) {
            if (modal.state === 'visible' || modal.state === 'hidden') {
                modal.state = 'hidden';
                await modal.promise.promise;
                this.removeModal(modal);
            } else {
                // Modal was not yet visible, just remove it cleanly - no animation needed
                modal.promise.resolve(value);
                this.removeModal(modal);
            }
        }

        this.handleBodyScrollLock();

        return Promise.resolve(value);
    }

    public removeModal(modal: Modal) {
        if (!modal.promise.isSettled)
            modal.promise.resolve();

        this.dialogs.value.splice(this.dialogs.value.indexOf(modal), 1);

        this.handleBodyScrollLock();
    }

    private getNextZIndex(): number {
        return Math.max(10000, Math.max(...this.dialogs.value.map((dialog) => dialog.zIndex)) + 1);
    }

    private handleBodyScrollLock() {
        if (!this.activeModal.value) {
            document.body.style.paddingRight = '';
            document.body.style.setProperty('--dialog-padding', '0px');
        } else {
            document.body.style.setProperty('--dialog-padding', `${window.scrollbarWidth?.value ?? 0}px`);
        }
    }
}

export default new ModalService();
