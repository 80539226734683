import { defineComponent, computed } from 'vue';
import { breakpoints, BreakPoints, screens } from '@/styling/screens';

const breakpointsArray = Object.keys(screens) as (keyof BreakPoints)[];

const handleBreakpoints = (breakpoint: string) => {
    const bps = (breakpoint.split('-') as (keyof BreakPoints)[]);

    switch (bps.length) {
    case 1:
        return breakpoints[breakpoint].value;
    case 2:
        return breakpoints[bps[0]].value && !breakpoints[bps[1]].value;
    default:
        console.error(`Illegal breakpoint ${breakpoint}`);
        return false;
    }
};

const handleArrayOfBreakpoints = (breakpoint: string) => {
    const bps = (breakpoint.split(',') as (keyof BreakPoints)[]);

    const isBpActive = (bp: keyof BreakPoints) => {
        const index = breakpointsArray.indexOf(bp);
        const nextBp = breakpointsArray[index + 1];
        const prevBp = breakpointsArray[index - 1];

        return breakpoints[bp].value && (!nextBp || !breakpoints[nextBp].value) && (!prevBp || breakpoints[prevBp].value);
    };

    return bps.some(isBpActive);
};

export default defineComponent({
    setup(_, { slots }) {
        const activeSlots = computed(() =>
            Object
                .entries(slots)
                .filter(([_, slot]) => typeof slot === 'function')
                .filter(([breakpoint]) => {
                    const isArray = breakpoint.includes(',');
                    if (isArray) {
                        return handleArrayOfBreakpoints(breakpoint);
                    }

                    return handleBreakpoints(breakpoint);
                })
                .map(([, slot]) => slot!()));

        return () => activeSlots.value;
    },
});
