<template>
    <nav class="navigation">
        <LeftMenuNavigationItem v-for="item in navItems"
                                :key="item.name"
                                class="nav-item"
                                :item="item"
                                :icon-only="iconOnly"/>
    </nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useNavigationItems } from '@/components/navigation/navigationItems';
import LeftMenuNavigationItem from './LeftMenuNavigationItem.vue';

export default defineComponent({
    name: 'LeftMenuNavigation',
    components: {
        LeftMenuNavigationItem,
    },
    props: {
        iconOnly: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const navItems = useNavigationItems();
        
        return {
            navItems,
        };
    },
});
</script>

<style lang="scss" scoped>
.navigation {
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex-grow: 1;

    .nav-item {
        border-bottom: 1px solid theme('colors.background');
    }
}
</style>
