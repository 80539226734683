import { apiInstanceFactory } from '@/core/api/api.factory';
import { ApiBase } from '@/core/api/api.base';
import { AddOrUpdateCartRequest, AddOrUpdateLineItemsRequest, CartViewObject, OrderViewObject, PagedOrdersViewModel, SearchOrderHistoryRequest, SearchOrdersRequest } from '@/api';

const api = apiInstanceFactory();

class IpInformationService extends ApiBase {
    public async addOrUpdateCart(request: AddOrUpdateCartRequest): Promise<CartViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.shopAddOrUpdateCartCreate(request), { suppressErrors: true });
    }

    public async addOrUpdateLineItems(request: AddOrUpdateLineItemsRequest): Promise<CartViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.shopAddOrUpdateLineItemsCreate(request), { suppressErrors: true });
    }

    public async getCart(): Promise<CartViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.shopGetCartList());
    }

    public async clearCart(): Promise<CartViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.shopClearCartCreate());
    }

    public async reOrder(orderId: string): Promise<OrderViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.shopReOrderCreate({ orderId }));
    }

    public async submitOrder(): Promise<OrderViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.shopSubmitOrderCreate());
    }

    public async searchOrderHistory(request: SearchOrderHistoryRequest): Promise<PagedOrdersViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.shopSearchOrderHistoryCreate(request));
    }

    public async searchOrders(request: SearchOrdersRequest): Promise<PagedOrdersViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.shopSearchOrdersCreate(request));
    }

    public async deleteOrder(orderId: string): Promise<OrderViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.shopDeleteOrderDelete({ orderId }));
    }
}

export default new IpInformationService();
