<template>
    <nav class="top-nav">
        <div class="nav-content">
            <div class="nav-container --left">
                <div class="mr-2 lg:mr-5">
                    <MenuButton v-model="leftMenuExpanded" height="35" width="35"/>
                </div>
                <div class="logo-container top-nav-seperator">
                    <router-link :to="{ name: $routes.HOME }" class="flex items-center">
                        <img src="/assets/images/logo-white.png" alt="imendo-logo" class="logo-image">
                    </router-link>
                </div>
            </div>
            <div class="nav-container --right text-14">
                <div class="mr-4 lg:mr-6">
                    <button class="flex items-center" @click="openChat()">
                        <p class="hidden lg:block mr-2">
                            Beskeder <span v-if="unreadMessageCount" class="text-yellow-500 font-bold">{{ unreadMessageCount }}</span>
                        </p>
                        <CIcon name="message-text"
                               fill
                               height="22"
                               width="22"/>
                    </button>
                </div>

                <div class="mr-4 lg:mr-6">
                    <button class="flex items-center" @click="showHistory()">
                        <p class="hidden lg:block mr-2">
                            Historisk oversigt
                        </p>
                        <CIcon name="history"
                               fill
                               height="22"
                               width="22"/>
                    </button>
                </div>

                <!-- <div class="unassigned-tasks top-nav-seperator">
                    <MenuButton v-model="rightMenuExpanded"
                                height="35"
                                width="35">
                        <template #left>
                            <p class="mr-2">
                                <span class="mr-4 hidden md:inline-block">Nye Opgaver</span>
                                <span v-if="unassignedTasksCount"
                                      class="font-bold text-red-500"
                                      :class="{ 'animate__animated animate__flash animate__infinite animate__slow': !rightMenuExpanded }"
                                      v-html="unassignedTasksCount"/>
                            </p>
                        </template>
                    </MenuButton>
                </div> -->
            </div>
        </div>
    </nav>
</template>

<script lang="ts">
import { defineAsyncComponent, defineComponent } from 'vue';
import { dashboardStore } from '@/store/dashboard/dashboard.store';
import { userStore } from '@/core/store/user/user.store';
import { chatStore } from '@/store/chat/chat.store';
import dialogService from '@/core/dialog/dialog.service';

export default defineComponent({
    name: 'TopNavigation',
    setup() {
        const { user } = userStore;
        const { unreadMessageCount } = chatStore;
        const { leftMenuExpanded, rightMenuExpanded } = dashboardStore;

        return {
            user,
            unreadMessageCount,
            leftMenuExpanded,
            rightMenuExpanded,

            showHistory: () => {
                dialogService.showModal({
                    component: defineAsyncComponent({ loader: () => import('@/components/logging/GlobalHistoryLog.vue') }),
                    componentAttrs: {
                    },
                }, {
                    size: 'lg',
                });
            },

            openChat: () => {
                dialogService.showModal({
                    component: defineAsyncComponent({ loader: () => import('@/components/chat/ChatHandler.vue') }),
                }, {
                    size: 'md',
                });
            },
        };
    },
});
</script>

<style lang="scss" scoped>
.top-nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: var(--top-navigation-height);
    display: flex;
    flex-direction: row;
    align-items: center;

    border-bottom: 1px solid theme('colors.border');
    box-shadow: 0px 0px 5px #0000008F;
    padding: 0.5rem 1rem;

    @screen lg {
        padding: 0.5rem 1rem;
    }

    @apply z-topMenu bg-background;

    .nav-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .nav-container {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            &.--right {
                flex: 1 0 auto;
            }

            .logo-container {
                user-select: none;
                display: flex;
                align-items: center;

                .logo-image {
                    height: 28px;
                    margin-right: 8px;

                    @screen md {
                        height: 40px;
                        margin-right: 10px;
                    }
                }
            }

            .unassigned-tasks {
                display: flex;
                align-items: center;
            }
        }
    }
}

.top-nav-seperator {
    border-left: 2px solid theme('colors.border');
    padding-left: 1rem;

    @screen lg {
        padding-left: 1.5rem;
    }
}
</style>
