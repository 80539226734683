import { readonly, ref } from 'vue';
import guidGenerator from '../guid/guidGenerator.service';

export interface Notification {
    position: 'top' | 'bottom' | 'center';
    alignment: 'left' | 'right' | 'center';
    type: 'success' | 'warning' | 'error';
    duration: number;
    message?: string;
    title: string;
    id: string;
}

class NotificationsService {
    private state = ref<Array<Notification>>([]);

    public notify({ title, message, type = 'success', position = 'top', alignment = 'right', duration = 3000 }:
        { title: string, message?: string, type?: 'success' | 'warning' | 'error', alignment?: 'left' | 'right' | 'center', position?: 'top' | 'bottom' | 'center', duration?: number }) {
        const id = guidGenerator.newGuid();

        this.state.value.unshift({
            id: id,
            title: title,
            message: message,
            type: type,
            position: position,
            alignment: alignment,
            duration: duration,
        });
    }

    public get notifications() {
        return readonly(this.state);
    }

    public removeNotification(id: string) {
        const index = this.state.value.findIndex(x => x.id === id);
        if (index >= 0) {
            this.state.value.splice(index, 1);
        }
    }
}

export default new NotificationsService();
