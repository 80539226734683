import { LoginRequest, LoginResponse, RegisterRequest, UserRole, UserViewObject } from '@/api';
import { RouteMetaExtended, RouteNames } from '@/router/router.routes';
import { userStore } from '@/core/store/user/user.store';
import { LocalStorageKeys } from '@/project/localStorageKeys';
import { useLocalStorage } from '@vueuse/core';
import { defineAsyncComponent } from 'vue';
import dialogService from '../dialog/dialog.service';
import authenticationApiService from '@/core/api/controllers/authenticationApi.service';
import router from '@/router/router.index';
import serverHubClient from '@/core/signalr/serverHub.client';
import bus from '@/core/bus';
import notificationsService from '@/core/notifications/notifications.service';

const authenticationToken = useLocalStorage<string>(LocalStorageKeys.TOKEN, '');

class AuthenticationService {
    private employeesOnly: boolean = false;

    public setEmployeesOnly(value: boolean)  {
        this.employeesOnly = value;
    }

    public async tryRefreshSession() {
        if (!authenticationToken.value) return;

        const result = await authenticationApiService.refreshSession();
        if (result) {
            await this.refreshSession(result.user, result.token);
        } else {
            await this.logout();
        }
    }

    public async refreshSession(user: UserViewObject, token: string) {
        await serverHubClient.stop();
        authenticationToken.value = token;
        userStore.setUser(user);
        await serverHubClient.start();
    }
    
    public async loginPrompt(navigateToAfterSuccesfulLogin?: keyof typeof RouteNames) {
        await dialogService.showModal({
            component: defineAsyncComponent({ loader: () => import('@/components/authentication/Login.vue') }),
        }, {
            size: 'xs',
        });

        if (navigateToAfterSuccesfulLogin && authenticationToken.value) {
            await router.push({ name: navigateToAfterSuccesfulLogin });
        }
    }

    public async login(request: LoginRequest): Promise<LoginResponse | undefined> {
        const result = await authenticationApiService.login(request);
        if (result) {
            if (this.employeesOnly && result.user.role === UserRole.None) {
                notificationsService.notify({
                    title: 'Ingen adgang',
                    message: 'Din bruger har ikke adgang til at tilgå dette site',
                    type: 'error',
                    duration: 5000,
                });
                return undefined;
            }
            authenticationToken.value = result.token;
            userStore.setUser(result.user);
            await serverHubClient.start();
            bus.emit('LOGGED_IN');
        }
        return result;
    }

    public async logout() {
        authenticationToken.value = '';
        userStore.clear();
        await serverHubClient.stop();
        const currentRoute = router.currentRoute.value;
        const meta = currentRoute.meta as RouteMetaExtended;
        if (!meta.allowAnonymous) {
            await router.push({ name: RouteNames.HOME });
        }

        bus.emit('LOGGED_OUT');
    }

    public async register(request: RegisterRequest): Promise<LoginResponse | undefined> {
        const result = await authenticationApiService.register(request);
        if (result) {
            authenticationToken.value = result.token;
            userStore.setUser(result.user);
            await serverHubClient.start();
            bus.emit('LOGGED_IN');
        }
        return result;
    }

    constructor() {
        bus.on('UserRemoved', userId => {
            if (userStore.user.value?.id === userId) {
                this.logout();
            }
        });
    }
}

export default new AuthenticationService();
