import { AddressViewObject } from '@/api';
import { isEqual, mergeWith, camelCase, merge } from 'lodash-es';
import { CSSProperties } from 'vue';

export const ValidImageFiles = ['.jpg', '.jpeg', '.png', 'gif', '.tga'];
export const ImageAccept = '.jpg,.jpeg,.png,.gif,.tga';

export enum TimeConstants {
    MINUTE = 60,
    HOUR = MINUTE * 60,
    DAY = HOUR * 24,
    WEEK = DAY * 7,
    MONTH = WEEK * 4,
    YEAR = WEEK * 52,
    IN_MILLISECONDS = 1000,
}

// eslint-disable-next-line @typescript-eslint/ban-types
type NonFunctional<T> = T extends Function ? never : T;

/**
 * Helper to produce an array of enum values.
 * @param enumeration Enumeration object.
 */
export function enumToArray<T extends object>(enumeration: T): NonFunctional<T[keyof T]>[] {
    return Object.keys(enumeration)
        .filter(key => isNaN(Number(key)))
        .map(key => enumeration[key])
        .filter(val => typeof val === 'number' || typeof val === 'string');
}

export enum CustomHeaders {
    Token = 'x-token',
    TokenExpired = 'x-token-expired',
    Customer = 'x-customer'
}

export enum CustomRequestHeaders {
    SignalRConnectionId = 'x-connection-id',
    Culture = 'x-culture',
    Timezone = 'x-timezone',
    Language = 'x-language',
    BusinessEntityId = 'x-businessentity-id',
}

export function parseJwt(token: string): { WebsiteVersion: string } {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export function getObjectDifference(oldObj: Record<string, unknown>, newObj: Record<string, unknown>) {
    return mergeWith(oldObj, newObj, function(objectValue, sourceValue, key, object, source) {
        if (!(isEqual(objectValue, sourceValue)) && (Object(objectValue) !== objectValue)) {
            console.log(object, key + '\n    Expected: ' + sourceValue + '\n    Actual: ' + objectValue);
        }
    });
}

export function addressEquals(a?: AddressViewObject | null, b?: AddressViewObject | null) {
    if (!a || !b) return false;
    
    return a.country === b.country &&
           a.city === b.city &&
           a.street === b.street &&
           a.postalCode === b.postalCode;
}

export const getPropertyValue = (obj: Object | null, propertyPath: string) => {
    for (const path of propertyPath.split('.'))
    {
        const camelCased = camelCase(path);
        const value = obj?.[camelCased];
        if (value == null)
            return null;

        obj = value;
    }

    return obj;
};

export function getTextHeightAndWidth(text: string, style: CSSProperties) {
    const span = document.createElement('span');
    document.body.appendChild(span);
  
    merge(span.style, style);
    span.style.position = 'fixed';
    span.style.top = '-1000px';
    span.style.left = '-1000px';
    span.style.whiteSpace = 'no-wrap';
    span.innerHTML = text;
  
    const width = Math.ceil(span.clientWidth);
    const height = Math.ceil(span.clientHeight);
  
    document.body.removeChild(span);

    return { width, height};
}

export function arraySwap<T = any>(array: T[], i: number, j: number) { [array[i], array[j]] = [array[j], array[i]]; }

export function dateIntervalsOverlap(dateStartA: Date, dateEndA: Date, dateStartB: Date, dateEndB: Date) {
    return dateStartA < dateEndB && dateStartB < dateEndA;
}

export function dateIntervalIsWithinInterval(dateStartA: Date, dateEndA: Date, dateStartB: Date, dateEndB: Date) {
    return dateStartA >= dateStartB && dateEndA <= dateEndB;
}

export function isBetweenTwoDates(date: Date | string, start: Date | string, end?: Date | string) {
    if (typeof date === 'string') 
        date = new Date(date);
    if (typeof start === 'string') 
        start = new Date(start);
    if (typeof end === 'string') 
        end = new Date(end);

    return date >= start && (!end || date <= end);
}

export function isSameDate(date1: Date, date2: Date) {
    return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
}

export function formatToReadableURLParam(inputString?: string | null) {
    if (!inputString) return null;

    // Replace spaces with hyphens and remove other special characters
    const formattedString = inputString
        .replace(/ /g, '-')  // Replace spaces with hyphens
        .replace(/[^a-zA-Z0-9-]/g, '');  // Remove special characters except hyphens, letters, and numbers

    return formattedString.toLowerCase();
}

type DotPrefix<T extends string> = T extends '' ? '' : `.${T}`

export type NestedObjectPaths<T> =
    T extends Array<infer U> ? { [K in Exclude<keyof U, symbol>]: `${K}${DotPrefix<NestedObjectPaths<U[K]>>}` }[Exclude<keyof U, symbol>] :
    (T extends object ?
        { [K in Exclude<keyof T, symbol>]: `${K}${DotPrefix<NestedObjectPaths<T[K]>>}` }[Exclude<keyof T, symbol>]
        : '') extends infer D ? Extract<D, string> : never;

export function convertBlobOrFileToBase64(blobOrFile: Blob | File): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        
        reader.onload = () => {
            if (typeof reader.result === 'string') {
                const base64Data = reader.result.split(',')[1]; // Extract the base64 data (skip the data URI prefix)
                resolve(base64Data);
            } else {
                reject(new Error('Invalid result type. Expected a string.'));
            }
        };
        
        reader.onerror = () => {
            reject(new Error('Error reading the file.'));
        };
        
        reader.readAsDataURL(blobOrFile);
    });
}

export function createURLWithParams(baseURL: string, params: Record<string, string | number | undefined>) {
    const url = new URL(baseURL);
    Object.keys(params).forEach(key => {
        if (params[key] || params[key] === 0) { // Checks if the parameter is truthy, or explicitly zero
            url.searchParams.append(key, params[key] as string);
        }
    });
    return url.toString();
}