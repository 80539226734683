import { GroupChatMessageViewObject, GroupChatViewObject } from '@/api';
import { Store } from '@/core/store/store';
import { computed, ComputedRef, WritableComputedRef } from 'vue';
import { userStore } from '../../core/store/user/user.store';
import communicationApiService from '@/core/api/controllers/communicationApi.service';
import soundService from '@/core/sounds/sound.service';
import bus from '@/core/bus';

type Hooks = '';

interface ChatState extends Record<string, unknown> {
    chats: GroupChatViewObject[];
    activeChatId: string | null | undefined;
    chatHandlerActive: boolean;
}

function containsOnlyMembers(chat: GroupChatViewObject, userId1: string, userId2: string) {
    if (chat.members.length !== 2) {
        return false;
    }
  
    if (
        (chat.members[0].userId === userId1 && chat.members[1].userId === userId2) ||
        (chat.members[0].userId === userId2 && chat.members[1].userId === userId1)
    ) {
        return true;
    }
  
    return false;
}

class ChatStore extends Store<ChatState, Hooks> {
    protected data(): ChatState {
        return {
            chats: [],
            activeChatId: null,
            chatHandlerActive: false,
        };
    }

    public async getChats() {
        const result = await communicationApiService.getChats();
        if (result) {
            this.addOrUpdateChats(result.groupChats);
            this.updateObjectLastUpdateTimer(this.state.chats);
        }
    }

    public async createChat(members: string[], title: string | null | undefined): Promise<GroupChatViewObject> {
        const result = await communicationApiService.createChat({ members, title });
        if (result) {
            this.addOrUpdateChat(result);
            this.setActiveChat(result);
        }
        return result;
    }

    public async deleteChat(chatId: string): Promise<GroupChatViewObject | undefined> {
        const result = await communicationApiService.deleteChat(chatId);
        if (result) {
            this.removeChat(result.id);
        }
        return result;
    }

    public async renameChat(chatId: string, title: string | null | undefined): Promise<GroupChatViewObject | undefined> {
        const result = await communicationApiService.renameChat(chatId, title);
        if (result) {
            this.addOrUpdateChat(result);
        }
        return result;
    }

    public async addUsersToChat(chatId: string, userIds: string[]): Promise<GroupChatViewObject | undefined> {
        const result = await communicationApiService.addUsersToChat({ chatId, userIds});
        if (result) {
            this.addOrUpdateChat(result);
        }
        return result;
    }

    public async removeUsersFromChat(chatId: string, userIds: string[]): Promise<GroupChatViewObject | undefined> {
        const result = await communicationApiService.removeUsersFromChat({ chatId, userIds});
        if (result) {
            this.addOrUpdateChat(result);
        }
        return result;
    }

    public async selectSingleChatWithUser(userId: string) {
        if (!userStore.user.value) return;

        if (!this.state.chats.length) {
            await this.getChats();
        }

        const chat = this.state.chats.find(x => containsOnlyMembers(x, userStore.user.value!.id, userId));
        if (chat) {
            this.setActiveChat(chat);
        } else {
            await this.createChat([userId], null);
        }

        return this.activeChat.value;
    }

    public get groupChats(): WritableComputedRef<GroupChatViewObject[]> {
        this.staleWhileRevalidate<GroupChatViewObject[] | void>({
            target: this.state.chats,
            action: async() => await this.getChats(),
            cacheTimeMs: 30000,
        });

        return computed(() => this.state.chats);
    }

    public get activeChat(): ComputedRef<GroupChatViewObject | null | undefined> {
        return computed(() => this.state.activeChatId ? this.state.chats.find(x => x.id === this.state.activeChatId) : null);
    }

    public setActiveChat(chat: GroupChatViewObject | null | undefined) {
        if (this.activeChat.value && this.activeChat.value.id !== chat?.id)
            this.activeChat.value.messages = [];

        this.state.activeChatId = chat?.id;
        this.state.chatHandlerActive = !!chat;
    }

    public async markChatAsRead(chat: GroupChatViewObject) {
        if (!userStore.user.value) return;

        const unreadMessages = chat.messages.filter(m => m.senderUserId !== userStore.user.value!.id && !m.readBy.includes(userStore.user.value!.id));
        if (unreadMessages.length > 0){
            unreadMessages.forEach(m => {
                m.readBy.push(userStore.user.value!.id);
            });

            await communicationApiService.markChatAsRead(chat.id);
        }
    }

    public async setChatHandlerActiveState(state: boolean) {
        this.state.chatHandlerActive = state;
    }

    public get unreadMessageCount() {
        return computed(() => this.groupChats.value
            .map(x => x.messages)
            .flat()
            .filter(x => userStore.user.value && x.senderUserId !== userStore.user.value.id && !x.readBy.includes(userStore.user.value.id))
            .length);
    }

    public async sendChatMessage(message: string, chatId: string) {
        await communicationApiService.sendChatMessage({ message, chatId });
    }

    private addOrUpdateChats(chats: GroupChatViewObject[]) {
        for (const chat of chats) {
            this.addOrUpdateChat(chat);
        }
    }

    private addOrUpdateChat(chat: GroupChatViewObject) {
        if (chat.members.length < 2) {
            this.removeChat(chat.id);
            return;
        }
        
        const existing = this.state.chats.find(x => x.id === chat.id);
        if (existing) {
            existing.created = chat.created;
            existing.createdBy = chat.createdBy;
            existing.members = chat.members;
            existing.title = chat.title;
            existing.updated = chat.updated;
        } else {
            this.state.chats.push(chat);
        }
    }

    private removeChat(chatId: string) {
        const index = this.state.chats.findIndex(x => x.id === chatId);
        if (index >= 0) {
            this.state.chats.splice(index, 1);
        }
    }

    private addChatMessage(message: GroupChatMessageViewObject) {
        if (!userStore.user.value) return;

        const chat = this.state.chats.find(x => x.id === message.chatId);
        if (chat) {
            chat.messages.push(message);
        }

        if (message.senderUserId !== userStore.user.value.id) {
            soundService.play('notification');
        }
    }

    public clear() {
        this.state.chats = [];
        this.state.activeChatId = null;
        this.state.chatHandlerActive = false;
    }

    constructor() {
        super();

        bus.on('GroupChatCreatedOrUpdated', (chat: GroupChatViewObject) => {
            this.addOrUpdateChat(chat);
        });

        bus.on('GroupChatRemoved', (chatId: string) => {
            this.removeChat(chatId);
        });

        bus.on('ChatMessageReceived', (message: GroupChatMessageViewObject) => {
            this.addChatMessage(message);
        });

        bus.on('SIGNALR_RECONNECT', () => {
            this.getChats();
        });

        bus.on('LOGGED_OUT', () => {
            this.clear();
        });
    }
}

export const chatStore = new ChatStore();
