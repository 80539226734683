<template>
    <nav class="menu-sidebar" :class="{ 'active': leftMenuExpanded }">
        <LeftMenuNavigation :icon-only="!leftMenuExpanded"/>
        <ProfileMenu class="flex-shrink-0" :icon-only="!leftMenuExpanded"/>
    </nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { dashboardStore } from '@/store/dashboard/dashboard.store';
import LeftMenuNavigation from '@/components/navigation/left/LeftMenuNavigation.vue';
import ProfileMenu from './ProfileMenu.vue';

export default defineComponent({
    name: 'LeftMenu',
    components: {
        LeftMenuNavigation,
        ProfileMenu,
    },
    setup() {
        const { leftMenuExpanded } = dashboardStore;

        return {
            leftMenuExpanded,
        };
    },
});
</script>

<style lang="scss" scoped>
.menu-sidebar {
    background-color: theme('colors.elements');
    box-shadow: 0px 0px 10px theme('colors.border');
    border-right: 0.0625rem solid theme('colors.border');

    width: 57px;

    @screen lg {
        width: 60px;
    }

    display: flex;
    flex-direction: column;

    overflow: hidden;

    flex-shrink: 0;

    &.active {
        width: 180px;
    }

    transition: width 300ms ease-in-out;
}
</style>
