<template>
    <Transition
        v-if="isLoggedIn"
        mode="out-in"
        appear
        :enter-active-class="enterActiveClass"
        :leave-active-class="leaveActiveClass">
        <div v-if="!isConnected" class="server-connection-status">
            <Popper hover
                    placement="auto"
                    offset-distance="10"
                    content="Forbindelsen til serveren er gået tabt. Der forsøges at oprette forbindelse igen..."
                    arrow
                    class="text-14">
                <div class="alert-icon animate__animated animate__flash animate__slower animate__infinite">
                    <CIcon name="wifi-alert" height="25" width="25"/>
                </div>
            </Popper>
        </div>
        <div v-else-if="reconnected" class="server-connection-status">
            <Popper hover
                    placement="auto"
                    offset-distance="10"
                    content="Forbindelsen til serveren er blevet genoprettet"
                    arrow
                    class="text-14">
                <div class="success-icon">
                    <CIcon name="wifi-check" height="25" width="25"/>
                </div>
            </Popper>
        </div>
    </Transition>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';
import { useAnimation } from '@/core/animation/animateComposable';
import { userStore } from '@/core/store/user/user.store';
import serverHubClient from '@/core/signalr/serverHub.client';

export default defineComponent({
    name: 'ServerConnectionStatus',
    setup() {
        const { isLoggedIn } = userStore;
        const { enterActiveClass, leaveActiveClass } = useAnimation({ type: 'fade' });

        const isConnected = computed(() => serverHubClient.isConnected.value);
        const reconnected = ref(false);
        let reconnectedStatusTimeout: any = null;

        watch(isConnected, (isConnectedA, wasConnected) => {
            if (isConnectedA && !wasConnected) {
                reconnected.value = true;
                clearTimeout(reconnectedStatusTimeout);
                reconnectedStatusTimeout = setTimeout(() => {
                    reconnected.value = false;
                }, 5000);
            }
        });
        
        return {
            isLoggedIn,
            enterActiveClass,
            leaveActiveClass,
            isConnected,
            reconnected,
        };
    },
});
</script>

<style lang="scss" scoped>
.server-connection-status {
    position: fixed;
    bottom: 10px;
    right: 20px;
    z-index: 10000000;
    color: black;

    display: flex;
    justify-content: center;
    align-items: center;

    .alert-icon,
    .success-icon {
        cursor: pointer;
        padding: 10px;
        border-radius: 9999px;
        border: 4px solid black;
    }

    .alert-icon {
        background-color: theme('colors.orange.500');
    }

    .success-icon {
        background-color: theme('colors.green.500');
    }
}
</style>
