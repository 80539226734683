import { AdminRouteAreaAccess, UserRole } from '@/api';
import { RouteLocationNormalized, RouteMeta, RouteRecordRaw } from 'vue-router';

export interface RouteMetaExtended extends RouteMeta {
    allowAnonymous?: boolean;
    role?: UserRole;
    area: AdminRouteAreaAccess;
}

export enum RouteNames {
    AUTH = 'AUTH',
    AUTH_LOGIN = 'AUTH_LOGIN',
    AUTH_FORGOT_PASSWORD = 'AUTH_FORGOT_PASSWORD',
    AUTH_RESET_PASSWORD = 'AUTH_RESET_PASSWORD',
    HOME = 'HOME',
    DASHBOARD = 'DASHBOARD',
    CUSTOMERS = 'CUSTOMERS',
    CUSTOMERS_OVERVIEW = 'CUSTOMERS_OVERVIEW',
    CUSTOMERS_CUSTOMER = 'CUSTOMERS_CUSTOMER',
    CUSTOMERS_CUSTOMER_INFORMATION = 'CUSTOMERS_CUSTOMER_INFORMATION',
    CUSTOMERS_CUSTOMER_ORDERS = 'CUSTOMERS_CUSTOMER_ORDERS',
    CUSTOMERS_CUSTOMER_ADDRESSES = 'CUSTOMERS_CUSTOMER_ADDRESSES',
    CUSTOMERS_CUSTOMER_CONTACTS = 'CUSTOMERS_CUSTOMER_CONTACTS',
    CUSTOMERS_CUSTOMER_USERS = 'CUSTOMERS_CUSTOMER_USERS',
    CUSTOMERS_CUSTOMER_PRODUCTS = 'CUSTOMERS_CUSTOMER_PRODUCTS',
    CUSTOMERS_CUSTOMER_PRODUCT_GROUPS = 'CUSTOMERS_CUSTOMER_PRODUCT_GROUPS',
    USERS = 'USERS',
    USERS_OVERVIEW = 'USERS_OVERVIEW',
    USERS_USER = 'USERS_USER',
    USERS_USER_INFORMATION = 'USERS_USER_INFORMATION',
    USERS_USER_CUSTOMER_ACCESSES = 'USERS_USER_CUSTOMER_ACCESSES',
    USERS_USER_ORDER_HISTORY = 'USERS_USER_ORDER_HISTORY',
    STATISTICS = 'STATISTICS',
    DICTIONARY = 'DICTIONARY',
    DICTIONARY_LABELS = 'DICTIONARY_LABELS',
    DICTIONARY_LANGUAGES = 'DICTIONARY_LANGUAGES',
    ADMINISTRATION = 'ADMINISTRATION',
    ADMINISTRATION_SETTINGS = 'ADMINISTRATION_SETTINGS',
    PRODUCTS = 'PRODUCTS',
    PRODUCTS_OVERVIEW = 'PRODUCTS_OVERVIEW',
    PRODUCTS_PRODUCT = 'PRODUCTS_PRODUCT',
    PRODUCTS_PRODUCT_INFORMATION = 'PRODUCTS_PRODUCT_INFORMATION',
    PRODUCTS_PRODUCT_IMAGES = 'PRODUCTS_PRODUCT_IMAGES',
    PRODUCTS_PRODUCT_ASSETS = 'PRODUCTS_PRODUCT_ASSETS',
    PRODUCTS_PRODUCT_CUSTOMER_ACCESSES = 'PRODUCTS_PRODUCT_CUSTOMER_ACCESSES',
    PRODUCTS_PRODUCT_GROUP_ACCESSES = 'PRODUCTS_PRODUCT_GROUP_ACCESSES',
    PRODUCTS_PRODUCT_CATEGORIES = 'PRODUCTS_PRODUCT_CATEGORIES',
    PRODUCTS_PRODUCT_VARIANTS = 'PRODUCTS_PRODUCT_VARIANTS',
    PRODUCTS_PRODUCT_SEO = 'PRODUCTS_PRODUCT_SEO',
    PRODUCTS_GROUPS = 'PRODUCTS_GROUPS',
    PRODUCTS_GROUPS_OVERVIEW = 'PRODUCTS_GROUPS_OVERVIEW',
    PRODUCTS_GROUPS_GROUP = 'PRODUCTS_GROUPS_GROUP',
    PRODUCTS_GROUPS_GROUP_INFORMATION = 'PRODUCTS_GROUPS_GROUP_INFORMATION',
    PRODUCTS_GROUPS_GROUP_CUSTOMER_ACCESSES = 'PRODUCTS_GROUPS_GROUP_CUSTOMER_ACCESSES',
    PRODUCTS_GROUPS_GROUP_PRODUCT_ACCESSES = 'PRODUCTS_GROUPS_GROUP_PRODUCT_ACCESSES',
    PRODUCTS_CATEGORIES = 'PRODUCTS_CATEGORIES',
    PRODUCTS_CATEGORIES_OVERVIEW  = 'PRODUCTS_CATEGORIES_OVERVIEW',
    ORDER_HISTORY = 'ORDER_HISTORY'
}

const createPath = (...paths: string[]) => {
    return `/${paths.join('/')}`;
};

export const routes: Array<RouteRecordRaw> = [
    {
        path: '',
        name: RouteNames.HOME,
        redirect: {
            name: RouteNames.DASHBOARD,
        },
        children: [
        ],
    },
    {
        path: createPath('dashboard'),
        name: RouteNames.DASHBOARD,
        component: () => import('@/pages/dashboard/Index.vue'),
        meta: {
            allowAnonymous: false,
            noPadding: true,
            area: AdminRouteAreaAccess.Dashboard,
        } as RouteMetaExtended,
        children: [
        ],
    },
    {
        path: createPath('customers'),
        name: RouteNames.CUSTOMERS,
        component: () => import('@/pages/customers/Index.vue'),
        redirect: {
            name: RouteNames.CUSTOMERS_OVERVIEW,
        },
        meta: {
            allowAnonymous: false,
            noPadding: true,
            area: AdminRouteAreaAccess.Customers,
        } as RouteMetaExtended,
        children: [
            {
                path: createPath('customers', 'overview'),
                name: RouteNames.CUSTOMERS_OVERVIEW,
                component: () => import('@/pages/customers/Overview.vue'),
                meta: {
                    allowAnonymous: false,
                    noPadding: true,
                    area: AdminRouteAreaAccess.Customers,
                } as RouteMetaExtended,
            },
            {
                path: createPath('customers', ':customerId'),
                name: RouteNames.CUSTOMERS_CUSTOMER,
                component: () => import('@/pages/customers/customer/Customer.vue'),
                meta: {
                    allowAnonymous: false,
                    area: AdminRouteAreaAccess.Customers,
                } as RouteMetaExtended,
                redirect: { name: RouteNames.CUSTOMERS_CUSTOMER_INFORMATION },
                props: (route: RouteLocationNormalized) => ({
                    customerId: route.params.customerId,
                }),
                children: [
                    {
                        path: createPath('customers', ':customerId', 'information'),
                        name: RouteNames.CUSTOMERS_CUSTOMER_INFORMATION,
                        component: () => import('@/pages/customers/customer/CustomerInformations.vue'),
                        meta: {
                            allowAnonymous: false,
                            area: AdminRouteAreaAccess.Customers,
                        } as RouteMetaExtended,
                    },
                    {
                        path: createPath('customers', ':customerId', 'contacts'),
                        name: RouteNames.CUSTOMERS_CUSTOMER_CONTACTS,
                        component: () => import('@/pages/customers/customer/CustomerContacts.vue'),
                        meta: {
                            allowAnonymous: false,
                            area: AdminRouteAreaAccess.Customers,
                        } as RouteMetaExtended,
                    },
                    {
                        path: createPath('customers', ':customerId', 'users'),
                        name: RouteNames.CUSTOMERS_CUSTOMER_USERS,
                        component: () => import('@/pages/customers/customer/CustomerUsers.vue'),
                        meta: {
                            allowAnonymous: false,
                            area: AdminRouteAreaAccess.Customers,
                        } as RouteMetaExtended,
                    },
                    {
                        path: createPath('customers', ':customerId', 'products'),
                        name: RouteNames.CUSTOMERS_CUSTOMER_PRODUCTS,
                        component: () => import('@/pages/customers/customer/CustomerProductAccesses.vue'),
                        meta: {
                            allowAnonymous: false,
                            area: AdminRouteAreaAccess.Customers,
                        } as RouteMetaExtended,
                    },
                    {
                        path: createPath('customers', ':customerId', 'product-groups'),
                        name: RouteNames.CUSTOMERS_CUSTOMER_PRODUCT_GROUPS,
                        component: () => import('@/pages/customers/customer/CustomerProductGroupAccesses.vue'),
                        meta: {
                            allowAnonymous: false,
                            area: AdminRouteAreaAccess.Customers,
                        } as RouteMetaExtended,
                    },
                    {
                        path: createPath('customers', ':customerId', 'orders'),
                        name: RouteNames.CUSTOMERS_CUSTOMER_ORDERS,
                        component: () => import('@/pages/customers/customer/CustomerOrders.vue'),
                        meta: {
                            allowAnonymous: false,
                            area: AdminRouteAreaAccess.Customers,
                        } as RouteMetaExtended,
                    },
                ],
            },
        ],
    },
    {
        path: createPath('users'),
        name: RouteNames.USERS,
        component: () => import('@/pages/users/Index.vue'),
        redirect: {
            name: RouteNames.USERS_OVERVIEW,
        },
        meta: {
            allowAnonymous: false,
            noPadding: true,
            area: AdminRouteAreaAccess.Users,
        } as RouteMetaExtended,
        children: [
            {
                path: createPath('users', 'overview'),
                name: RouteNames.USERS_OVERVIEW,
                component: () => import('@/pages/users/Overview.vue'),
                meta: {
                    allowAnonymous: false,
                    noPadding: true,
                    area: AdminRouteAreaAccess.Users,
                } as RouteMetaExtended,
            },
            {
                path: createPath('users', ':userId'),
                name: RouteNames.USERS_USER,
                component: () => import('@/pages/users/user/User.vue'),
                meta: {
                    allowAnonymous: false,
                    noPadding: true,
                    area: AdminRouteAreaAccess.Users,
                } as RouteMetaExtended,
                redirect: { name: RouteNames.USERS_USER_INFORMATION },
                props: (route: RouteLocationNormalized) => ({
                    userId: route.params.userId,
                }),  
                children: [
                    {
                        path: createPath('users', ':userId', 'information'),
                        name: RouteNames.USERS_USER_INFORMATION,
                        component: () => import('@/pages/users/user/UserInformation.vue'),
                        meta: {
                            allowAnonymous: false,
                            area: AdminRouteAreaAccess.Users,
                        } as RouteMetaExtended,
                    },
                    {
                        path: createPath('users', ':userId', 'customer-accesses'),
                        name: RouteNames.USERS_USER_CUSTOMER_ACCESSES,
                        component: () => import('@/pages/users/user/UserCustomerAccesses.vue'),
                        meta: {
                            allowAnonymous: false,
                            area: AdminRouteAreaAccess.Users,
                        } as RouteMetaExtended,
                    },
                    {
                        path: createPath('users', ':userId', 'order-history'),
                        name: RouteNames.USERS_USER_ORDER_HISTORY,
                        component: () => import('@/pages/users/user/UserOrderHistory.vue'),
                        meta: {
                            allowAnonymous: false,
                            area: AdminRouteAreaAccess.Users,
                        } as RouteMetaExtended,
                    },
                ],
            },
        ],
    },
    {
        path: createPath('products'),
        name: RouteNames.PRODUCTS,
        component: () => import('@/pages/products/Index.vue'),
        redirect: {
            name: RouteNames.PRODUCTS_OVERVIEW,
        },
        meta: {
            allowAnonymous: false,
            noPadding: true,
            area: AdminRouteAreaAccess.Products,
        } as RouteMetaExtended,
        children: [
            {
                path: createPath('products', 'overview'),
                name: RouteNames.PRODUCTS_OVERVIEW,
                component: () => import('@/pages/products/Overview.vue'),
                meta: {
                    allowAnonymous: false,
                    area: AdminRouteAreaAccess.Products,
                } as RouteMetaExtended,
            },
            {
                path: createPath('products', ':productId'),
                name: RouteNames.PRODUCTS_PRODUCT,
                component: () => import('@/pages/products/product/Product.vue'),
                meta: {
                    allowAnonymous: false,
                    noPadding: true,
                    area: AdminRouteAreaAccess.Products,
                } as RouteMetaExtended,
                redirect: { name: RouteNames.PRODUCTS_PRODUCT_INFORMATION },
                props: (route: RouteLocationNormalized) => ({
                    productId: route.params.productId,
                }),  
                children: [
                    {
                        path: createPath('products', ':productId', 'information'),
                        name: RouteNames.PRODUCTS_PRODUCT_INFORMATION,
                        component: () => import('@/pages/products/product/ProductInformation.vue'),
                        meta: {
                            allowAnonymous: false,
                            area: AdminRouteAreaAccess.Products,
                        } as RouteMetaExtended,
                    },
                    {
                        path: createPath('products', ':productId', 'images'),
                        name: RouteNames.PRODUCTS_PRODUCT_IMAGES,
                        component: () => import('@/pages/products/product/ProductImages.vue'),
                        meta: {
                            allowAnonymous: false,
                            area: AdminRouteAreaAccess.Products,
                        } as RouteMetaExtended,
                    },
                    {
                        path: createPath('products', ':productId', 'assets'),
                        name: RouteNames.PRODUCTS_PRODUCT_ASSETS,
                        component: () => import('@/pages/products/product/ProductAssets.vue'),
                        meta: {
                            allowAnonymous: false,
                            area: AdminRouteAreaAccess.Products,
                        } as RouteMetaExtended,
                    },
                    {
                        path: createPath('products', ':productId', 'customer-accesses'),
                        name: RouteNames.PRODUCTS_PRODUCT_CUSTOMER_ACCESSES,
                        component: () => import('@/pages/products/product/ProductCustomerAccesses.vue'),
                        meta: {
                            allowAnonymous: false,
                            area: AdminRouteAreaAccess.Products,
                        } as RouteMetaExtended,
                    },
                    {
                        path: createPath('products', ':productId', 'groups'),
                        name: RouteNames.PRODUCTS_PRODUCT_GROUP_ACCESSES,
                        component: () => import('@/pages/products/product/ProductGroupAccesses.vue'),
                        meta: {
                            allowAnonymous: false,
                            area: AdminRouteAreaAccess.Products,
                        } as RouteMetaExtended,
                    },
                    {
                        path: createPath('products', ':productId', 'categories'),
                        name: RouteNames.PRODUCTS_PRODUCT_CATEGORIES,
                        component: () => import('@/pages/products/product/ProductCategories.vue'),
                        meta: {
                            allowAnonymous: false,
                            area: AdminRouteAreaAccess.Products,
                        } as RouteMetaExtended,
                    },
                    {
                        path: createPath('products', ':productId', 'variants'),
                        name: RouteNames.PRODUCTS_PRODUCT_VARIANTS,
                        component: () => import('@/pages/products/product/ProductVariants.vue'),
                        meta: {
                            allowAnonymous: false,
                            area: AdminRouteAreaAccess.Products,
                        } as RouteMetaExtended,
                    },
                    {
                        path: createPath('products', ':productId', 'seo'),
                        name: RouteNames.PRODUCTS_PRODUCT_SEO,
                        component: () => import('@/pages/products/product/ProductSEO.vue'),
                        meta: {
                            allowAnonymous: false,
                            area: AdminRouteAreaAccess.Products,
                        } as RouteMetaExtended,
                    },
                ],
            },
        ],
    },
    {
        path: createPath('products', 'groups'),
        name: RouteNames.PRODUCTS_GROUPS,
        component: () => import('@/pages/product-groups/Index.vue'),
        redirect: {
            name: RouteNames.PRODUCTS_GROUPS_OVERVIEW,
        },
        meta: {
            allowAnonymous: false,
            area: AdminRouteAreaAccess.ProductGroups,
        } as RouteMetaExtended,
        children: [
            {
                path: createPath('products', 'groups', 'overview'),
                name: RouteNames.PRODUCTS_GROUPS_OVERVIEW,
                component: () => import('@/pages/product-groups/Overview.vue'),
                meta: {
                    allowAnonymous: false,
                    area: AdminRouteAreaAccess.ProductGroups,
                } as RouteMetaExtended,
            },
            {
                path: createPath('products', 'groups', ':groupId'),
                name: RouteNames.PRODUCTS_GROUPS_GROUP,
                component: () => import('@/pages/product-groups/group/ProductGroup.vue'),
                meta: {
                    allowAnonymous: false,
                    noPadding: true,
                    area: AdminRouteAreaAccess.ProductGroups,
                } as RouteMetaExtended,
                redirect: { name: RouteNames.PRODUCTS_GROUPS_GROUP_INFORMATION },
                props: (route: RouteLocationNormalized) => ({
                    groupId: route.params.groupId,
                }),  
                children: [
                    {
                        path: createPath('products', 'groups', ':groupId', 'information'),
                        name: RouteNames.PRODUCTS_GROUPS_GROUP_INFORMATION,
                        component: () => import('@/pages/product-groups/group/ProductGroupInformation.vue'),
                        meta: {
                            allowAnonymous: false,
                            area: AdminRouteAreaAccess.ProductGroups,
                        } as RouteMetaExtended,
                    },
                    {
                        path: createPath('products', 'groups', ':groupId', 'customer-accesses'),
                        name: RouteNames.PRODUCTS_GROUPS_GROUP_CUSTOMER_ACCESSES,
                        component: () => import('@/pages/product-groups/group/ProductGroupCustomerAccesses.vue'),
                        meta: {
                            allowAnonymous: false,
                            area: AdminRouteAreaAccess.ProductGroups,
                        } as RouteMetaExtended,
                    },
                    {
                        path: createPath('products', 'groups', ':groupId', 'product-accesses'),
                        name: RouteNames.PRODUCTS_GROUPS_GROUP_PRODUCT_ACCESSES,
                        component: () => import('@/pages/product-groups/group/ProductGroupProductAccesses.vue'),
                        meta: {
                            allowAnonymous: false,
                            area: AdminRouteAreaAccess.ProductGroups,
                        } as RouteMetaExtended,
                    },
                ],
            },
        ],
    },
    {
        path: createPath('products', 'categories'),
        name: RouteNames.PRODUCTS_CATEGORIES,
        component: () => import('@/pages/product-categories/Index.vue'),
        redirect: {
            name: RouteNames.PRODUCTS_CATEGORIES_OVERVIEW,
        },
        meta: {
            allowAnonymous: false,
            area: AdminRouteAreaAccess.ProductCategories,
        } as RouteMetaExtended,
        children: [
            {
                path: createPath('products', 'categories', 'overview'),
                name: RouteNames.PRODUCTS_CATEGORIES_OVERVIEW,
                component: () => import('@/pages/product-categories/Overview.vue'),
                meta: {
                    allowAnonymous: false,
                    area: AdminRouteAreaAccess.ProductCategories,
                } as RouteMetaExtended,
            },
            // {
            //     path: createPath('products', 'groups', ':groupId'),
            //     name: RouteNames.PRODUCTS_GROUPS_GROUP,
            //     component: () => import('@/pages/product-groups/group/ProductGroup.vue'),
            //     meta: {
            //         allowAnonymous: false,
            //         noPadding: true,
            //         area: AdminRouteAreaAccess.ProductGroups,
            //     } as RouteMetaExtended,
            //     redirect: { name: RouteNames.PRODUCTS_GROUPS_GROUP_INFORMATION },
            //     props: (route: RouteLocationNormalized) => ({
            //         groupId: route.params.groupId,
            //     }),  
            //     children: [
            //         {
            //             path: createPath('products', 'groups', ':groupId', 'information'),
            //             name: RouteNames.PRODUCTS_GROUPS_GROUP_INFORMATION,
            //             component: () => import('@/pages/product-groups/group/ProductGroupInformation.vue'),
            //             meta: {
            //                 allowAnonymous: false,
            //                 area: AdminRouteAreaAccess.ProductGroups,
            //             } as RouteMetaExtended,
            //         },
            //         {
            //             path: createPath('products', 'groups', ':groupId', 'customer-accesses'),
            //             name: RouteNames.PRODUCTS_GROUPS_GROUP_CUSTOMER_ACCESSES,
            //             component: () => import('@/pages/product-groups/group/ProductGroupCustomerAccesses.vue'),
            //             meta: {
            //                 allowAnonymous: false,
            //                 area: AdminRouteAreaAccess.ProductGroups,
            //             } as RouteMetaExtended,
            //         },
            //         {
            //             path: createPath('products', 'groups', ':groupId', 'product-accesses'),
            //             name: RouteNames.PRODUCTS_GROUPS_GROUP_PRODUCT_ACCESSES,
            //             component: () => import('@/pages/product-groups/group/ProductGroupProductAccesses.vue'),
            //             meta: {
            //                 allowAnonymous: false,
            //                 area: AdminRouteAreaAccess.ProductGroups,
            //             } as RouteMetaExtended,
            //         },
            //     ],
            // },
        ],
    },
    {
        path: createPath('order-history'),
        name: RouteNames.ORDER_HISTORY,
        component: () => import('@/pages/orders/Index.vue'),
        meta: {
            allowAnonymous: false,
            area: AdminRouteAreaAccess.Orders,
        } as RouteMetaExtended,
    },
    {
        path: createPath('dictionary'),
        name: RouteNames.DICTIONARY,
        component: () => import('@/pages/dictionary/Index.vue'),
        meta: {
            allowAnonymous: false,
            area: AdminRouteAreaAccess.Dictionary,
        } as RouteMetaExtended,
        children: [
            {
                path: createPath('dictionary', 'labels'),
                name: RouteNames.DICTIONARY_LABELS,
                component: () => import('@/pages/dictionary/Labels.vue'),
                meta: {
                    allowAnonymous: false,
                    area: AdminRouteAreaAccess.Dictionary,
                } as RouteMetaExtended,
            },
            {
                path: createPath('dictionary', 'languages'),
                name: RouteNames.DICTIONARY_LANGUAGES,
                component: () => import('@/pages/dictionary/Languages.vue'),
                meta: {
                    allowAnonymous: false,
                    area: AdminRouteAreaAccess.Dictionary,
                } as RouteMetaExtended,
            },
        ],
    },
    {
        path: createPath('auth'),
        name: RouteNames.AUTH,
        component: () => import('@/pages/login/LoginPage.vue'),
        meta: {
            allowAnonymous: true,
            area: AdminRouteAreaAccess.Auth,
        },
        children: [
            {
                path: createPath('auth', 'login'),
                name: RouteNames.AUTH_LOGIN,
                component: () => import('@/pages/login/LoginForm.vue'),
                meta: {
                    allowAnonymous: true,
                    area: AdminRouteAreaAccess.Auth,
                },
            },
            {
                path: createPath('auth', 'forgot-password'),
                name: RouteNames.AUTH_FORGOT_PASSWORD,
                component: () => import('@/pages/login/ForgotPasswordForm.vue'),
                meta: {
                    allowAnonymous: true,
                    area: AdminRouteAreaAccess.Auth,
                },
            },
            {
                path: createPath('auth', 'reset-password'),
                name: RouteNames.AUTH_RESET_PASSWORD,
                component: () => import('@/pages/login/ResetPasswordForm.vue'),
                props: (route: RouteLocationNormalized) => ({
                    token: route.query.token,
                }),
                meta: {
                    allowAnonymous: true,
                    area: AdminRouteAreaAccess.Auth,
                },
            },
        ],
    },
    {
        path: '/:pathMatch(.*)*',
        component: () => import('@/pages/404.vue'),
        meta: {
            allowAnonymous: true,
        },
    },
];
