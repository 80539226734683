import { App } from 'vue';
import dictionary from '@/core/dictionary/dictionary';

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $toTime: (timeInSecs: number, shortText?: boolean, hoursOnly?: boolean, hideSeconds?: boolean) => string;
        $toTimeFromNow: (date: Date | string, showMultipleUnits?: boolean) => string;
    }
}

const MINUTE = 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const WEEK = DAY * 7;

const second = () => dictionary.get('Second');
const seconds = () => dictionary.get('Seconds');
const minute = () => dictionary.get('Minute');
const minutes = () => dictionary.get('Minutes');
const hour = () => dictionary.get('Hour');
const hours = () => dictionary.get('Hours');
const day = () => dictionary.get('Day');
const days = () => dictionary.get('Days');
const week = () => dictionary.get('Week');
const weeks = () => dictionary.get('Weeks');
const month = () => dictionary.get('Month');
const months = () => dictionary.get('Months');
const timeAgo = () => dictionary.get('Time.TimeAgo');
const timeJustNow = () => dictionary.get('Time.JustNow');

export function toTime(timeInSecs: number, shortText = false, hoursOnly = false, hideSeconds = false): string {
    if (hideSeconds && timeInSecs < MINUTE) {
        return dictionary.get('LessThanAMinute');
    }

    const totalSecs = Math.floor(timeInSecs % MINUTE);
    const totalMinutes = Math.floor((timeInSecs % HOUR) / MINUTE);
    const totalHours = Math.floor((timeInSecs % DAY) / HOUR);
    const totalDays = Math.floor((timeInSecs % WEEK) / DAY);
    const totalWeeks = Math.floor(timeInSecs / WEEK);

    let ss = '';
    if (totalWeeks) ss = ss + totalWeeks + (shortText ? 'w' : totalWeeks > 1 ? ` ${weeks()} ` : ` ${week()} `);
    if (totalDays) ss = ss + totalDays + (shortText ? 'd' : totalDays > 1 ? ` ${days()} ` : ` ${day()} `);
    if (totalHours || hoursOnly) ss = ss + totalHours + (shortText ? 'h' : totalHours > 1 ? ` ${hours()} ` : ` ${hour()} `);
    if (!hoursOnly) {
        if (totalMinutes) ss = ss + totalMinutes + (shortText ? 'm' : totalMinutes > 1 ? ` ${minutes()} ` : ` ${minute()} `);
        else if (!hideSeconds && (totalSecs || (!totalDays && !totalHours && !totalMinutes && !totalWeeks))) {
            ss = ss + totalSecs + (shortText ? 's' : (totalSecs > 1 || totalSecs === 0) ? ` ${seconds()}` : ` ${second()}`);
        }
    }

    return ss.trim();
}

export function toTimeFromNow(date: Date | string, showMultipleUnits = false) {
    date = typeof date === 'string' ? new Date(date) : date;

    const now = new Date();
    const diffMs = now.getTime() - date.getTime();
    const diffSeconds = Math.floor(diffMs / 1000);
    const diffMinutes = Math.floor(diffSeconds / 60);
    const diffHours = Math.floor(diffMinutes / 60);
    const diffDays = Math.floor(diffHours / 24);
    const diffWeeks = Math.floor(diffDays / 7);
    const diffMonths = Math.floor(diffDays / 30);

    if (showMultipleUnits) {
        const units: string[] = [];

        if (diffMonths > 0) units.push(`${diffMonths} ${diffMonths > 1 ? months() : month()} ${timeAgo()}`);
        if (diffWeeks > 0) units.push(`${diffWeeks} ${diffWeeks > 1 ? weeks() : week()} ${timeAgo()}`);
        if (diffDays > 0) units.push(`${diffDays} ${diffDays > 1 ? days() : day()} ${timeAgo()}`);
        if (diffHours > 0) units.push(`${diffHours} ${diffHours > 1 ? hours() : hour()} ${timeAgo()}`);
        if (diffMinutes > 0) units.push(`${diffMinutes} ${diffMinutes > 1 ? minutes() : minute()} ${timeAgo()}`);
        if (diffSeconds > 0) units.push(`${diffSeconds} ${diffSeconds > 1 ? seconds() : second()} ${timeAgo()}`);

        return units.join(' ');
    } else {
        if (diffMonths > 0) return `${diffMonths} ${diffMonths > 1 ? months() : month()} ${timeAgo()}`;
        if (diffWeeks > 0) return `${diffWeeks} ${diffWeeks > 1 ? weeks() : week()} ${timeAgo()}`;
        if (diffDays > 0) return `${diffDays} ${diffDays > 1 ? days() : day()} ${timeAgo()}`;
        if (diffHours > 0) return `${diffHours} ${diffHours > 1 ? hours() : hour()} ${timeAgo()}`;
        if (diffMinutes > 0) return `${diffMinutes} ${diffMinutes > 1 ? minutes() : minute()} ${timeAgo()}`;
        if (diffSeconds > 0) return `${diffSeconds} ${diffSeconds > 1 ? seconds() : second()} ${timeAgo()}`;
  
        return timeJustNow();
    }
}

export default function config(app: App): void {
    app.config.globalProperties.$toTime = toTime;
    app.config.globalProperties.$toTimeFromNow = toTimeFromNow;
}
