import { Store } from '@/core/store/store';
import { computed, WritableComputedRef } from 'vue';
import bus from '@/core/bus';
import { breakpoints } from '@/styling/screens';
import { startOfDay } from 'date-fns';
import { LocalStorageService } from '@/core/storage/storage.service';

type Hooks = '';

export interface TaskOrganizerSettings {
    hideResourcesWithoutDrivers: boolean
    hideScrollToTopButton: boolean
}

interface DashboardState extends Record<string, unknown> {
    menus: {
        leftExpanded: boolean;
        rightExpanded: boolean;
    },
}

const today = () => startOfDay(new Date());

class DashboardStore extends Store<DashboardState, Hooks> {
    protected data(): DashboardState {
        return {
            activeView: LocalStorageService.getItem('dashboard-view-active-view'),
            activeViews: [],
            taskOrganizer: {
                date: today(),
                tasks: [],
                resourceSchedules: [],
                settings: LocalStorageService.getItemAs<TaskOrganizerSettings>('taskorganizer-settings') ?? {
                    hideResourcesWithoutDrivers: false,
                    hideScrollToTopButton: false,
                },
            },
            menus: {
                leftExpanded: breakpoints.isGreater('lg'),
                rightExpanded: false,
            },
        };
    }

    public toggleLeftMenu() {
        this.state.menus.leftExpanded = !this.state.menus.leftExpanded;
    }

    public toggleRightMenu() {
        this.state.menus.rightExpanded = !this.state.menus.rightExpanded;
    }

    public get leftMenuExpanded(): WritableComputedRef<boolean> {
        return computed({
            get: () => this.state.menus.leftExpanded,
            set: (value: boolean) => {
                if (value && this.state.menus.rightExpanded && breakpoints.isSmaller('lg')) {
                    this.state.menus.rightExpanded = false;
                }
                this.state.menus.leftExpanded = value;
            },
        });
    }

    public get rightMenuExpanded(): WritableComputedRef<boolean> {
        return computed({
            get: () => this.state.menus.rightExpanded,
            set: (value: boolean) => {
                if (value && this.state.menus.leftExpanded && breakpoints.isSmaller('lg')) {
                    this.state.menus.leftExpanded = false;
                }
                this.state.menus.rightExpanded = value;
            },
        });
    }

    public clear() {
    }

    constructor() {
        super();

        bus.on('LOGGED_OUT', () => {
            this.clear();
        });
    }
}

export const dashboardStore = new DashboardStore();
