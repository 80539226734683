import { DirectiveBinding } from 'vue';

export const ImageNotFound = `${window.location.origin}/images/0aa32496-df19-4a49-a854-e36ca5902e92/billede-paa-vej.png`;//'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

const onErrorDirective = {
    beforeMount(el: HTMLElement, binding: DirectiveBinding): void {
        if (!el.getAttribute('src'))
            el.setAttribute('src', ImageNotFound);
        else
            el.addEventListener('error', () => el.setAttribute('src', ImageNotFound));
    },
};

export default onErrorDirective;
