import { ref } from 'vue';
import guidGenerator from '@/core/guid/guidGenerator.service';
import keyboardService from '@/core/util/keyboard.service';

export interface ContextMenuItem {
    /**
     * The label of this menu item.
     *
     * Can be a callback. Use `h` to render custom content.
     *
     * ```js
     * {
     *   label: h('div', {
     *     style: {
     *       fontSize: '20px',
     *       color: '#f98',
     *     }
     *   }, "Item with custom render"),
     * },
     * ```
     */
    label: string;
    /**
     * By default, the submenu will automatically adjust its position to prevent it overflow the container.
     *
     * If you allow menu overflow containers, you can set this to false.
     *
     * Default is inherit from `MenuOptions.adjustPosition`.
     */
    adjustSubMenuPosition?: boolean;
    /**
     * When there are subitems in this item, is it allowed to trigger its own click event? Default is false
     */
    clickableWhenHasChildren?: boolean;
    /**
     * Should close menu when Click this menu item ?
     */
    clickClose?: boolean;
    /**
     * Is this menu item separated from the menu item?
     *
     * * `true` or `'down'`: Separator is show below menu.
     * * `'up'`: Separator is show above menu.
     * * `false`: No Separator.
     */
    divided?: boolean | 'up' | 'down';
    /**
     * Custom css class for submenu
     */
    customClass?: string;
    /**
     * Submenu maximum width (in pixels).
     */
    maxWidth?: number | string;
    /**
     * Submenu minimum width (in pixels).
     */
    minWidth?: number | string;
    /**
     * Menu item click event handler.
     *
     * @param e The current event of `click` or `keydown` (when user use keyboard press this menu)
     */
    onClick?: (e?: MouseEvent | KeyboardEvent) => void;
    /**
     * Child menu items (Valid in function mode).
     */
    children?: ContextMenuItem[];
    disabled?: boolean;
    active?: boolean;
}

export interface ContextMenuOptions {
    parentId?: string;
    parentMenu?: ContextMenu;
    parentItem?: ContextMenuItem;
    /**
     * The items for this menu.
     */
    items: ContextMenuItem[];
    /**
     * Menu display x position.
     */
    x: number;
    /**
     * Menu display y position.
     */
    y: number;
    /**
    * X-coordinate offset of submenu and parent menu.
    */
    xOffset?: number;
    /**
    * Y-coordinate offset of submenu and parent menu.
    */
    yOffset?: number;
    /**
     * Close when user scroll mouse ? Default is true.
     */
    closeWhenScroll?: boolean;
}

export interface ContextMenu {
    id: string;
    options: ContextMenuOptions;
}

export const MENU_WIDTH = Math.min(window.innerWidth * 0.9, 280);

class ContextMenuService {
    private timer: number | undefined = undefined;

    public menus = ref<Array<ContextMenu>>([]);

    constructor() {
        document.addEventListener('keyup', (e) => {
            if (this.menus.value.length === 0) return;

            if (keyboardService.isEscape(e)) {
                this.clearMenus();
            }
        });

        const handleMouseEvent = (e: MouseEvent) => {
            if (this.menus.value.length === 0) return;

            for (let i = this.menus.value.length - 1; i >= 0; i--) {
                const menu = this.menus.value[i];

                if (this.isMenuClicked(e, menu))
                    break;
            
                this.menus.value.splice(i, 1);
            }
        };

        document.addEventListener('click', handleMouseEvent);
        document.addEventListener('contextmenu', handleMouseEvent);
        // document.addEventListener('scroll', () => this.clearMenus());
    }

    private isMenuClicked(e: MouseEvent, menu: ContextMenu): boolean {
        const element = document.getElementById(menu.id);
        if (element && e.target && element.contains(e.target as Node)) {
            return true;
        }

        return false;
    }

    public cleanupHiereachy(parentItem: ContextMenuItem) {
        for (let i = this.menus.value.length - 1; i >= 0; i--) {
            const menu = this.menus.value[i];

            if (!menu.options.parentItem || menu.options.items.some(i => i === parentItem))
                break;
        
            this.menus.value.splice(i, 1);
        }
    }

    private canAddMenu(parentItem?: ContextMenuItem): boolean {
        if (!parentItem) 
            return true;

        if (this.menus.value.some(x => x.options.parentItem === parentItem))
            return false;

        this.cleanupHiereachy(parentItem);

        return true;
    }

    public showContextMenu(options: ContextMenuOptions): ContextMenu | undefined {
        this.timer && clearTimeout(this.timer);

        if (!this.canAddMenu(options.parentItem))
            return;

        const id = guidGenerator.newGuid();

        let x = options.x;
        const maxX = window.innerWidth - MENU_WIDTH - 15;

        if (options.parentMenu) {
            let parentMenu: ContextMenu | undefined = options.parentMenu;

            while (parentMenu) {
                const parentX = parentMenu.options.x;
                const parentRight = parentX + MENU_WIDTH;
                const parentWithChildIsOutOfBounds = parentRight + MENU_WIDTH > window.innerWidth;
                if (parentWithChildIsOutOfBounds) {
                    x = Math.max(15, Math.min(maxX, options.parentMenu.options.x - MENU_WIDTH));
                    break;
                }

                parentMenu = parentMenu.options.parentMenu;
            }
        } else {
            x = Math.max(15, Math.min(maxX, x));
        }

        const menu: ContextMenu = {
            id: id,
            options: {
                ...options,
                x: x,
            },
        };

        this.timer = setTimeout(() => {
            this.menus.value.push(menu);   
        });

        return menu;
    }

    public hideContextMenu(id: string) {
        const index = this.menus.value.findIndex(x => x.id === id);
        if (index >= 0) {
            this.menus.value.splice(index, 1);
        }
    }

    public clearMenus() {
        this.menus.value = [];
    }
}

export default new ContextMenuService();