import { TypedEmitter } from '@/core/event-emitter';
import { ProjectBusEvents } from '@/project/bus/index';
import { CustomerAddressViewObject, CustomerLockViewObject, CustomerUserAccessViewObject, CustomerViewObject, GroupChatMessageViewObject, GroupChatViewObject, HubConnectionViewObject, OrderViewObject, ProductCategoryViewObject, ProductCustomerAssetViewObject, ProductGroupLockViewObject, ProductGroupViewObject, ProductLockViewObject, ProductViewObject, UserCustomerAccessViewObject, UserViewObject } from '@/api';
import { onBeforeUnmount } from 'vue';

interface BusEvents extends ProjectBusEvents {
    'PROGRESS_START': () => void;
    'PROGRESS_FINISH': () => void;
    'SIGNALR_RECONNECT': (connectionId?: string) => void;
    'LOGGED_IN': () => void;
    'LOGGED_OUT': () => void;

    'CustomerUpdated': (customer: CustomerViewObject) => void;
    'CustomersUpdated': (customers: CustomerViewObject[]) => void;
    'CustomerCreated': (customer: CustomerViewObject) => void;
    'CustomerRemoved': (customerId: string) => void;
    'CustomerAddressUpdated': (address: CustomerAddressViewObject) => void;
    'CustomerAddressRemoved': (addressId: string) => void;
    'LockCustomer': (lock: CustomerLockViewObject) => void;
    'UnlockCustomer': (lock: CustomerLockViewObject) => void;
    'UsersAddedToCustomer': (userCustomerAccesses: CustomerUserAccessViewObject[], customerId: string) => void;
    'UsersRemovedFromCustomer': (userIds: string[], customerId: string) => void;

    'ProductUpdated': (product: ProductViewObject) => void;
    'ProductsUpdated': (products: ProductViewObject[]) => void;
    'ProductCreated': (product: ProductViewObject) => void;
    'ProductRemoved': (productId: string) => void;
    'LockProduct': (lock: ProductLockViewObject) => void;
    'UnlockProduct': (lock: ProductLockViewObject) => void;
    'ProductCustomerAssetsUpdated': (productId: string, assets: ProductCustomerAssetViewObject[]) => void;

    'ProductGroupUpdated': (product: ProductGroupViewObject) => void;
    'ProductGroupsUpdated': (products: ProductGroupViewObject[]) => void;
    'ProductGroupCreated': (product: ProductGroupViewObject) => void;
    'ProductGroupRemoved': (productId: string) => void;
    'LockProductGroup': (lock: ProductGroupLockViewObject) => void;
    'UnlockProductGroup': (lock: ProductGroupLockViewObject) => void;

    'ProductCategoryUpdated': (category: ProductCategoryViewObject) => void;
    'ProductCategoriesUpdated': (categories: ProductCategoryViewObject[]) => void;
    'ProductCategoryCreated': (category: ProductCategoryViewObject) => void;
    'ProductCategoryRemoved': (categoryId: string) => void;
    'ProductCategoriesRemoved': (categoryIds: string[]) => void;

    'UserCreated': (user: UserViewObject) => void;
    'UserUpdated': (user: UserViewObject) => void;
    'UsersUpdated': (users: UserViewObject[]) => void;
    'UserRemoved': (userId: string) => void;
    'OnConnected': (connection: HubConnectionViewObject) => void;
    'OnDisconnected': (userId: string, connectionId: string) => void;
    'OnConnectionUpdate': (connection: HubConnectionViewObject) => void;
    
    'ChatMessageReceived': (message: GroupChatMessageViewObject) => void;
    'GroupChatCreatedOrUpdated': (chat: GroupChatViewObject) => void;
    'GroupChatRemoved': (chatId: string) => void;

    'OrderUpdated': (order: OrderViewObject) => void;
    'OrderRemoved': (orderId: string) => void;
}

const bus = new TypedEmitter<BusEvents>();

export function useBusEvent<U extends keyof BusEvents>(event: U, listener: BusEvents[U]) {
    bus.on(event, listener);

    onBeforeUnmount(() => {
        bus.off(event, listener);
    });
}

export function useBusEvents(events: { [K in keyof Partial<BusEvents>]: BusEvents[K] }) {
    Object.entries(events).forEach(([event, listener]) => {
        if (listener) {
            bus.on(event as keyof BusEvents, listener as BusEvents[keyof BusEvents]);
        }
    });

    onBeforeUnmount(() => {
        Object.entries(events).forEach(([event, listener]) => {
            if (listener) {
                bus.off(event as keyof BusEvents, listener as BusEvents[keyof BusEvents]);
            }
        });
    });
}

export default bus;
