import { DeepReadonly, markRaw, onBeforeUnmount, reactive, readonly, UnwrapNestedRefs } from 'vue';
import { mergeWith, debounce } from 'lodash-es';
import bus from '@/core/bus';

type MonitoredObject<T = Record<string, any>> = T & {
    _changeTracking?: {
        isUpdating?: boolean;
        lastUpdated?: number;
        debounceTimer?: number;
    }
}

export interface RevalidationOptions {
    cacheTimeMs?: number;
    forceUpdate?: boolean;
    debounceTimer?: number;
}

interface RevelationAction<T> extends RevalidationOptions {
    target: NonNullable<MonitoredObject<T>>;
    action: () => Promise<T | null | undefined>;
    callback?: (target: T | null | undefined | void) => void;
}

type ListenerSignature<L> = {
    [E in keyof L]: (...args: any[]) => any;
};

type DefaultListener = {
    [k: string]: (...args: any[]) => any;
};

export abstract class Store<T extends Record<string, unknown>, L extends ListenerSignature<L> = DefaultListener> {
    protected state: T;

    constructor() {
        const data = this.data();
        this.state = reactive(data) as T;

        bus.on('LOGGED_OUT', () => {
            this.clear();
        });
    }

    public abstract clear(): void;

    protected abstract data(): T

    public getState(): T {
        return this.state;
    }

    public getReadonlyState(): DeepReadonly<UnwrapNestedRefs<T>> {
        return readonly(this.state);
    }

    private updateHooks: { [key: string]: Array<(...params: any[]) => void> } = {};
    private hookExecuteQueue: { [key: string]: Array<(...params: any[]) => void> } = {};
    private executeHooksInQueue() {
        const queue = { ...this.hookExecuteQueue };
        this.hookExecuteQueue = {};

        for (const [key, params] of Object.entries(queue)) {
            const hooks = this.updateHooks[key];
            if (!Array.isArray(hooks)) {
                continue;
            }

            for (const hook of hooks) {
                hook(params);
            }
        }
    }

    private hookExecuteDebounce = debounce(this.executeHooksInQueue, 5);

    protected addEventHook<U extends keyof L>(event: U, listener: L[U]): void {
        this.updateHooks[event as string] ??= [];
        this.updateHooks[event as string].push(listener);
    }

    public removeEventHook<U extends keyof L>(event: U, listener: L[U]): void {
        const hooks = this.updateHooks[event as string];
        if (Array.isArray(hooks)) {
            const index = hooks.indexOf(listener);
            if (index >= 0) {
                hooks.splice(index, 1);
            }
        }
    }

    public useEventHook<U extends keyof L>(event: U, listener: L[U]) {
        this.addEventHook(event, listener);
        onBeforeUnmount(() => this.removeEventHook(event, listener));
    }

    public useEventHooks<U extends keyof L>(events: U[], listener: L[U]): void {
        for (const key of events) {
            this.useEventHook(key, listener);
        }
    }

    protected enqueueHook<U extends keyof L>(event: U, ...params: Parameters<L[U]>): void {
        this.hookExecuteQueue[event as string] = params;
        this.hookExecuteDebounce();
    }

    protected staleWhileRevalidate<T>({
        target,
        action,
        callback,
        cacheTimeMs = 30000,
        forceUpdate = false,
        debounceTimer = 50,
    }: RevelationAction<T>): T {
        const now = new Date().getTime();
        target._changeTracking ??= markRaw({ isUpdating: undefined, lastUpdated: undefined, debounceTimer: undefined });

        if ((forceUpdate && target._changeTracking.lastUpdated) || (!target._changeTracking.isUpdating && (!cacheTimeMs || !target._changeTracking.lastUpdated || (target._changeTracking.lastUpdated + cacheTimeMs <= now)))) {
            if (target._changeTracking.debounceTimer) {
                clearTimeout(target._changeTracking.debounceTimer);
                target._changeTracking.debounceTimer = undefined;
            }

            target._changeTracking.debounceTimer = setTimeout(() => {
                target._changeTracking ??= markRaw({ isUpdating: undefined, lastUpdated: undefined, debounceTimer: undefined });
                target._changeTracking.isUpdating = true;
                action()
                    .then((value) => {
                        if (value) {
                            mergeWith(target, value, (a, b) => Array.isArray(b) ? b : undefined);
                        }

                        target._changeTracking ??= markRaw({ isUpdating: undefined, lastUpdated: undefined, debounceTimer: undefined });
                        target._changeTracking.lastUpdated = now;
                    }).finally(() => {
                        target._changeTracking ??= markRaw({ isUpdating: undefined, lastUpdated: undefined, debounceTimer: undefined });
                        target._changeTracking.isUpdating = false;

                        if (callback) {
                            callback(target);
                        }
                    });
            }, debounceTimer);
        }

        return target;
    }

    protected updateObjectLastUpdateTimer<T>(target: NonNullable<MonitoredObject<T>>): void {
        target._changeTracking ??= markRaw({ isUpdating: undefined, lastUpdated: undefined, debounceTimer: undefined });
        target._changeTracking.lastUpdated = new Date().getTime();
    }
}
