<template>
    <div class="notification" :class="[ notification.type ]">
        <div class="notification-content">
            <header class="notification-header">
                <strong>
                    {{ notification.title }}
                </strong>
                <div class="flex-shrink-0 ml-auto">
                    <InputClear class="input-clear" @clear="close"/>
                </div>
            </header>
            <div v-if="notification.message" class="notification-body">
                {{ notification.message }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, PropType } from 'vue';
import notificationService, { Notification } from './notifications.service';

export default defineComponent({
    name: 'Notification',
    props: {
        notification: {
            type: Object as PropType<Notification>,
            required: true,
        },
    },
    setup(props) {
        const close = () => {
            notificationService.removeNotification(props.notification.id);
        };

        if (props.notification.duration > 0) {
            onMounted(() => {
                setTimeout(() => {
                    close();
                }, props.notification.duration);
            });
        }

        return {
            close,
        };
    },
});
</script>

<style lang="scss" scoped>
.notification {
    display: block;
    position: relative;
    max-width: 350px;
    backface-visibility: hidden;
    background-clip: padding-box;
    z-index: 1;
    border-radius: .35rem;

    &:not(:last-child) {
        margin-bottom: .75rem;
    }

    &.success {
        @apply bg-green-600;
    }

    &.warning {
        @apply bg-amber-500;
    }

    &.error {
        @apply bg-red-600;
    }

    .notification-content {
        @apply shadow-lg;

        .notification-header {
            @apply px-6 py-4 text-13 font-bold flex items-center;
            border-bottom: 1px solid rgba(0,0,0,.1);
        }

        .notification-body {
            @apply px-6 py-4 text-12 break-words whitespace-pre-line;
        }
    }
}
</style>
