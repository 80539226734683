<template>
    <button v-if="user" class="user-profile" :class="{ active: !iconOnly }" @click="profile()">
        <UserProfileImage preview class="pointer-events-none"/>
            
        <div v-if="!iconOnly"
             class="flex flex-col justify-center items-center w-full mt-2">
            <p class="mb-1 text-14 lg:text-15">
                {{ user.name }} {{ user.lastName[0] }}
            </p>
            <p class="font-bold text-10 lg:text-11">
                {{ user.role }}
            </p>
            <button v-if="unreadMessageCount" class="text-11" @click.stop.capture="openChatHandler()">
                Ulæste beskeder: <span class="text-yellow-500 font-bold">{{ unreadMessageCount }}</span>
            </button>
        </div>
    </button>
</template>

<script lang="ts">
import { defineComponent, defineAsyncComponent } from 'vue';
import { userStore } from '@/core/store/user/user.store';
import { chatStore } from '@/store/chat/chat.store';
import dialogService from '@/core/dialog/dialog.service';

export default defineComponent({
    name: 'ProfileMenu',
    props: {
        iconOnly: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const { user } = userStore;
        const { unreadMessageCount } = chatStore;

        return {
            user,
            unreadMessageCount,
            profile: () => {
                dialogService.showModal({
                    description: 'Profil',
                    component: defineAsyncComponent({ loader: () => import('@/components/user/UserProfile.vue') }),
                }, {
                    size: 'sm',
                });
            },
            openChatHandler: () => {
                dialogService.showModal({
                    component: defineAsyncComponent({ loader: () => import('@/components/chat/ChatHandler.vue') }),
                }, {
                    size: 'md',
                });
            },
        };
    },
});
</script>

<style lang="scss" scoped>
.user-profile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    gap: 3px;

    padding: 6px 6px;

    &.active {
        padding: 6px 5px;
    }

    background-color: theme('colors.background');
    border-top: 1px solid theme('colors.border');
}
</style>
