<template>
    <nav class="menu-sidebar" :class="{ 'active': rightMenuExpanded }">
        <div v-if="rightMenuExpanded" class="menu-content">
        </div>
    </nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { dashboardStore } from '@/store/dashboard/dashboard.store';

export default defineComponent({
    name: 'RightMenu',
    components: {
    },
    setup() {
        const { rightMenuExpanded } = dashboardStore;

        return {
            rightMenuExpanded,
        };
    },
});
</script>

<style lang="scss" scoped>
.menu-sidebar {
    background-color: theme('colors.elements');
    box-shadow: 0 0.5rem 1rem 0 theme('colors.shadow');
    border-left: 0.0625rem solid #101426;

    width: 0;

    display: flex;
    flex-direction: column;
    height: 100%;

    overflow-y: auto;
    overflow-x: hidden;

    flex-shrink: 0;

    &.active {
        width: 250px;
    }

    @media screen and (max-width: 768px) {
        position: fixed;
        top: var(--top-navigation-height);
        right: 0;
        bottom: 0;
        z-index: 1000;

        &.active {
            width: 100vw;
        }
    }

    transition: width 300ms ease-in-out;

    .menu-content {
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;
        height: 100%;
        padding-right: 1px;
    }
}
</style>
