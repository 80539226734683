<template>
    <TopNavigation v-if="isLoggedIn"/>

    <main>
        <LeftMenu v-if="isLoggedIn" class="left-menu"/>

        <div class="content">
            <RouterView v-slot="{ Component }">
                <Transition
                    mode="out-in"
                    appear
                    :enter-active-class="enterActiveClass"
                    :leave-active-class="leaveActiveClass">
                    <component :is="Component"/>
                </Transition>
            </RouterView>
        </div>
    
        <RightMenu v-if="isLoggedIn" class="right-menu"/>
    </main>

    <ModalHost/>
    <ProgressBar/>
    <ContextMenuHost/>
    <NotificationsHost/>
    <ServerConnectionStatus/>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useAnimation } from '@/core/animation/animateComposable';
import { userStore } from '@/core/store/user/user.store';
import ModalHost from '@/core/dialog/ModalHost.vue';
import ContextMenuHost from '@/core/context-menus/ContextMenuHost.vue';
import NotificationsHost from '@/core/notifications/NotificationsHost.vue';
import ProgressBar from '@/core/loading/ProgressBar.vue';
import TopNavigation from '@/components/navigation/top/TopNavigation.vue';
import LeftMenu from '@/components/menus/LeftMenu.vue';
import RightMenu from '@/components/menus/RightMenu.vue';
import ServerConnectionStatus from '@/core/signalr/ServerConnectionStatus.vue';

export default defineComponent({
    name: 'App',
    components: {
        ModalHost,
        ContextMenuHost,
        NotificationsHost,
        ProgressBar,
        TopNavigation,
        LeftMenu,
        RightMenu,
        ServerConnectionStatus,
    },
    setup() {
        const { isLoggedIn } = userStore;
        const { enterActiveClass, leaveActiveClass } = useAnimation({ type: 'fade' });

        return {
            isLoggedIn,
            enterActiveClass,
            leaveActiveClass,
        };
    },
});
</script>

<style>
#app {
    position: relative;

    display: flex;
    flex-direction: column;
    flex: 1 0 auto;

    background-color: theme('colors.background');
}
</style>

<style lang="scss" scoped>
main {
    position: relative;
    display: flex;
    flex-direction: row;
    overflow: hidden;

    margin-top: var(--top-navigation-height);
    height: calc(100vh - var(--top-navigation-height));

    .content {
        position: relative;
        display: flex;
        flex-direction: column;
        overflow: auto;
        flex: 1 1 auto;
        padding: 7px;
    }
}
</style>
