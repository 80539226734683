class BaseStorage {
    readonly store: Storage;

    constructor(store: Storage) {
        this.store = store;
    }

    public isImplemented(): boolean {
        return true;
    }

    public getItem<T extends string = string>(key: string, validator?: { validOptions: T[], fallback: T }): T | undefined {
        const item = this.store.getItem(key) as T | undefined;
        if (validator) {
            return item ? validator.validOptions.includes(item) ? item : validator.fallback : validator.fallback;
        }

        return item;
    }

    public getInt(key: string): number | null {
        const val = this.store.getItem(key);
        return val == null || isNaN(val as any) ? null : parseInt(val, 10);
    }

    public getFloat(key: string): number | null {
        const val = this.store.getItem(key);
        return val == null || isNaN(val as any) ? null : parseFloat(val);
    }

    public getDate(key: string, resetTime = false): Date | null {
        try {
            const val = this.store.getItem(key);
            const date = val ? new Date(val) : null;
            resetTime && date?.setHours(0, 0, 0, 0);
            return date;
        } catch (e) {
            console.warn(e);
            this.store.removeItem(key);
            return null;
        }
    }

    public getItemAs<T>(key: string): T | null {
        const item = this.store.getItem(key);
        try {
            return item !== null ? (JSON.parse(item) as T) : null;
        } catch (err) {
            console.log(err);
            this.store.removeItem(key);
            return null;
        }
    }

    public setItemAs<T>(key: string, data: T): void {
        try {
            this.store.setItem(key, JSON.stringify(data));
        } catch (ex) {
            console.log(ex);
        }
    }

    public setItem(key: string, value: any): boolean {
        try {
            this.store.setItem(key, value);
            return true;
        } catch (ex) {
            return false;
        }
    }

    public removeItem(key: string): void {
        return this.store.removeItem(key);
    }

    public clear(): void {
        return this.store.clear();
    }
}

export const LocalStorageService = new BaseStorage(window.localStorage);
export const SessionStorageService = new BaseStorage(window.sessionStorage);
