import { apiInstanceFactory } from '@/core/api/api.factory';
import { ApiBase } from '@/core/api/api.base';
import { AddUsersToChatRequest, CreateChatRequest, GroupChatMessageViewObject, GroupChatMessagesViewModel, GroupChatViewModel, GroupChatViewObject, RemoveUserFromChatRequest, SendChatMessageRequest, 
} from '@/api';

const api = apiInstanceFactory();

class CommunicationService extends ApiBase {
    public async sendChatMessage(request: SendChatMessageRequest): Promise<GroupChatMessageViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.communicationSendChatMessageCreate(request));
    }

    public async createChat(request: CreateChatRequest): Promise<GroupChatViewObject> {
        const result = await this.callEndpointWithErrorHandling(() => api.api.communicationCreateChatCreate(request));
        return result!; // This is never null
    }

    public async deleteChat(chatId: string): Promise<GroupChatViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.communicationDeleteChatCreate({
            chatId: chatId,
        }));
    }

    public async renameChat(chatId: string, title: string | null | undefined): Promise<GroupChatViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.communicationRenameChatCreate({
            chatId: chatId,
            title: title,
        }));
    }

    
    public async markChatAsRead(chatId: string): Promise<boolean | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.communicationMarkChatAsReadByUserCreate({
            chatId: chatId,
        }));
    }

    public async removeUsersFromChat(request: RemoveUserFromChatRequest): Promise<GroupChatViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.communicationRemoveUsersFromChatCreate(request));
    }

    public async addUsersToChat(request: AddUsersToChatRequest): Promise<GroupChatViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.communicationAddUsersToChatCreate(request));
    }

    public async getChat(chatId: string): Promise<GroupChatViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.communicationGetChatList({
            chatId: chatId,
        }));
    }

    public async getChats(): Promise<GroupChatViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.communicationGetChatsList());
    }

    public async getMessagesForChat(chatId: string, skip: number, take: number): Promise<GroupChatMessagesViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.communicationGetMessagesForChatList({
            chatId, skip, take,
        }));
    }
}

export default new CommunicationService();
