import { ChangePasswordRequest, LoginRequest, LoginResponse, RefreshSessionResponse, RegisterRequest, RequestPasswordResetRequest, ResetPasswordRequest } from '@/api';
import { apiInstanceFactory } from '@/core/api/api.factory';
import { ApiBase } from '@/core/api/api.base';

const api = apiInstanceFactory();

class UserService extends ApiBase {
    public async login(request: LoginRequest): Promise<LoginResponse | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.authLoginCreate(request));
    }

    public async register(request: RegisterRequest): Promise<LoginResponse | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.authRegisterCreate(request));
    }

    public async refreshSession(): Promise<RefreshSessionResponse | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.authRefreshSessionList(), {
            retries: 3,
        });
    }

    public async requestPasswordReset(request: RequestPasswordResetRequest): Promise<boolean | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.authRequestPasswordResetCreate(request));
    }

    public async resetPassword(request: ResetPasswordRequest): Promise<boolean | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.authResetPasswordCreate(request));
    }

    public async changePassword(request: ChangePasswordRequest): Promise<RefreshSessionResponse | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.authChangePasswordCreate(request));
    }
}

export default new UserService();
