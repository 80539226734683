import { App, defineAsyncComponent } from 'vue';

const InputCheckbox = defineAsyncComponent(() => import('./InputCheckbox.vue'));
const InputSwitch = defineAsyncComponent(() => import('./InputSwitch.vue'));
const InputErrors = defineAsyncComponent(() => import('./InputErrors.vue'));
const InputClear = defineAsyncComponent(() => import('./InputClear.vue'));
const InputRadio = defineAsyncComponent(() => import('./InputRadio.vue'));
const InputSelect = defineAsyncComponent(() => import('./InputSelect.vue'));
const InputMultiSelect = defineAsyncComponent(() => import('./InputMultiSelect.vue'));
const InputPostalCode = defineAsyncComponent(() => import('./InputPostalCode.vue'));
const InputAddress = defineAsyncComponent(() => import('./InputAddress.vue'));
const InputText = defineAsyncComponent(() => import('./InputText.vue'));
const InputTextArea = defineAsyncComponent(() => import('./InputTextArea.vue'));
const InputDatePicker = defineAsyncComponent(() => import('./InputDatePicker.vue'));
const InputTags = defineAsyncComponent(() => import('./InputTags.vue'));
const InputErrorMessage = defineAsyncComponent(() => import('./InputErrorMessage.vue'));

export default function config(app: App<Element>): void {
    app.component('InputCheckbox', InputCheckbox);
    app.component('InputSwitch', InputSwitch);
    app.component('InputErrors', InputErrors);
    app.component('InputClear', InputClear);
    app.component('InputRadio', InputRadio);
    app.component('InputSelect', InputSelect);
    app.component('InputMultiSelect', InputMultiSelect);
    app.component('InputPostalCode', InputPostalCode);
    app.component('InputAddress', InputAddress);
    app.component('InputText', InputText);
    app.component('InputTextArea', InputTextArea);
    app.component('InputDatePicker', InputDatePicker);
    app.component('InputTags', InputTags);
    app.component('InputErrorMessage', InputErrorMessage);
}
