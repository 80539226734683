<template>
    <Transition
        mode="out-in"
        appear
        :enter-active-class="enterActiveClass"
        :leave-active-class="leaveActiveClass">
        <span :key="value">
            {{ value }}
        </span>
    </Transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useAnimation } from './animateComposable';

export default defineComponent({
    name: 'AnimateValue',
    props: {
        value: {
            type: [String, Number],
            required: true,
        },
    },
    setup() {
        const { enterActiveClass, leaveActiveClass } = useAnimation({ type: 'fade' });

        return {
            enterActiveClass, leaveActiveClass,
        };
    },
});
</script>
