import { AddOrUpdateDictionaryLanguageRequest, DictionaryLabelByLanguageViewModel, DictionaryLabelsByLanguageViewModel, DictionaryLabelsViewModel, DictionaryLanguageViewModel, DictionaryLanguagesViewModel, MarkLabelAsMissingRequest, UpdateDictionaryLabelRequest,
} from '@/api';
import { apiInstanceFactory } from '@/core/api/api.factory';
import { ApiBase } from '@/core/api/api.base';

const api = apiInstanceFactory();

class DictionaryService extends ApiBase {
    public async cacheBust(): Promise<boolean | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.dictionaryCacheBustCreate());
    }

    //#region Admin

    public async getSupportedAdminLanguages(): Promise<DictionaryLanguagesViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.dictionaryGetSupportedAdminLanguagesList());
    }

    public async getAdminDictionaryLabelsForArea(area?: string | null): Promise<DictionaryLabelsViewModel  | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.dictionaryGetAdminDictionaryLabelsForAreaList({
            area: area!,
        }));
    }

    public async getAdminDictionaryLabels(): Promise<DictionaryLabelsByLanguageViewModel  | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.dictionaryGetAdminDictionaryLabelsList());
    }

    public async markAdminLabelAsMissingRequest(request: MarkLabelAsMissingRequest): Promise<boolean | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.dictionaryMarkAdminLabelAsMissingCreate(request));
    }

    public async addOrUpdateAdminDictionaryLabel(request: UpdateDictionaryLabelRequest): Promise<DictionaryLabelByLanguageViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.dictionaryAddOrUpdateAdminDictionaryLabelCreate(request));
    }

    public async addOrUpdateAdminDictionaryLanguage(request: AddOrUpdateDictionaryLanguageRequest): Promise<DictionaryLanguageViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.dictionaryAddOrUpdateAdminDictionaryLanguageCreate(request));
    }

    //#endregion

    //#region shop

    public async getSupportedShopLanguages(): Promise<DictionaryLanguagesViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.dictionaryGetSupportedShopLanguagesList());
    }

    public async getShopDictionaryLabelsForArea(area?: string | null): Promise<DictionaryLabelsViewModel  | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.dictionaryGetShopDictionaryLabelsForAreaList({
            area: area!,
        }));
    }

    public async getShopDictionaryLabels(): Promise<DictionaryLabelsByLanguageViewModel  | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.dictionaryGetShopDictionaryLabelsList());
    }

    public async markShopLabelAsMissingRequest(request: MarkLabelAsMissingRequest): Promise<boolean | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.dictionaryMarkShopLabelAsMissingCreate(request));
    }

    public async addOrUpdateShopDictionaryLabel(request: UpdateDictionaryLabelRequest): Promise<DictionaryLabelByLanguageViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.dictionaryAddOrUpdateShopDictionaryLabelCreate(request));
    }

    public async addOrUpdateShopDictionaryLanguage(request: AddOrUpdateDictionaryLanguageRequest): Promise<DictionaryLanguageViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.dictionaryAddOrUpdateShopDictionaryLanguageCreate(request));
    }

    //#endregion
}

export default new DictionaryService();
