<template>
    <div class="tabs" :class="{ 'vertical': vertical }">
        <ul v-if="!hideContainer && state.tabs.length > 1" class="tabs-container" :class="[tabsContainerClass, {'fullWidth': fullWidth}]">
            <li
                v-for="tab in state.tabs"
                :key="tab.props.name"
                class="tab-item"
                :class="{ 'active': tab.props.name.toLowerCase() === state.selectedTab?.toLowerCase() }">
                <button class="tab-link"
                        type="button"
                        :title="tab.props.name"
                        :class="tab.props.tabStyle"
                        @click.prevent="selectTab(tab.props.name)">
                    <span>{{ tab.props.name }}</span>
                </button>

                <slot v-if="tab.props.id" :name="tab.props.id"/>
            </li>
        </ul>

        <slot name="static"/>

        <div class="tab-content" :class="tabContentClass">
            <slot/>
        </div>
    </div>
</template>

<script lang="ts">
import router from '@/router/router.index';
import {
    defineComponent,
    onMounted,
    provide,
    reactive,
    watch,
} from 'vue';

export interface TabsState {
  selectedTab: string;
  tabs: any[];
}

export default defineComponent({
    name: 'Tabs',
    props: {
        useHash: {
            type: Boolean,
            default: false,
        },
        startTab: {
            type: String,
            required: false,
        },
        vertical: {
            type: Boolean,
            default: false,
        },
        hideContainer: {
            type: Boolean,
            default: false,
        },
        tabContentClass: {
            type: String,
            default: '',
        },
        tabsContainerClass: {
            type: String,
            default: '',
        },
        fullWidth: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const state = reactive<TabsState>({
            selectedTab: props.startTab ?? '',
            tabs: [],
        });

        provide('TabsProvider', state);

        onMounted(() => {
            if (props.useHash) {
                const hash = router.currentRoute.value.hash;
                if (hash) {
                    selectTab(hash.slice(1));
                }
            }

            if (!state.selectedTab) {
                selectTab();
            }
        });

        const selectTab = (tab?: string) => {
            if (state.tabs.length === 0 || state.selectedTab === tab) {
                return;
            }

            const prevTabSelected = !!state.selectedTab;

            if (tab && state.tabs.some(x => x.props.name.toLowerCase() === tab.toLowerCase())) {
                state.selectedTab = tab.toLowerCase();
            } else {
                state.selectedTab = state.tabs[0].props.name.toLowerCase();
            }

            if (state.selectedTab && props.useHash) {
                if (prevTabSelected) {
                    router.push({ hash: '#' + state.selectedTab.toLowerCase(), query: router.currentRoute.value.query, params: router.currentRoute.value.params });
                } else {
                    router.replace({ hash: '#' + state.selectedTab.toLowerCase(), query: router.currentRoute.value.query, params: router.currentRoute.value.params });
                }
            }

            emit('tabChanged', state.selectedTab);
        };

        watch(() => router.currentRoute.value.hash, (hash, oldHash) => {
            if (props.useHash && hash && hash !== oldHash) {
                selectTab(hash.slice(1));
            }
        });

        return {
            state,
            selectTab,
        };
    },
});
</script>

<style lang="scss" scoped>
.tabs {
    display: flex;
    flex-direction: column;

    .tabs-container {
        display: flex;
        flex-direction: column;
        border: 2px solid theme("colors.border");

        flex-shrink: 0;
        
        .tab-item {
            border-bottom: 2px solid theme("colors.border");
            flex-shrink: 0;
            display: flex;
            align-items: center;
            position: relative;

            &:last-child {
                border-bottom: none;
            }

            @apply whitespace-nowrap bg-tabs-item text-tabs-color;

            &.active {
                @apply  bg-tabs-itemActive text-tabs-activeColor;
            }

            .tab-link {
                @apply py-3 px-8 text-13 w-full flex-shrink-0 text-left;
            }
        }

        @screen lg {
            @apply inline-flex flex-row w-fit;

            .tab-item {
                border-bottom: none;
                border-right: 2px solid theme("colors.border");

                &:last-child {
                    border-right: none;
                }

                .tab-link {
                    text-align: center;
                }
            }
        }

        &.fullWidth {
            width: 100%;

            .tab-item {
                flex: 1 0 auto;
                text-align: center;

                .tab-link {
                    text-align: center;
                }
            }
        }
    }

    @screen lg {
        &:not(.vertical) {
            .tab-content {
                display: flex;
                flex-direction: column;
                flex: 1 1 auto;      
                &:not(.overflow-hidden) {
                    overflow: visible;
                }
            }
        }

        &.vertical {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;

            .tabs-container {
                flex-direction: column;
                flex-shrink: 0;
                margin-right: 15px;
                max-width: 200px;
                height: fit-content;

                .tab-item {
                    border-bottom: 1px solid theme("colors.border");
                    border-right: none;

                    .tab-link {
                        text-align: left;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }

            .tab-content {
                flex: 1 1 auto;
                overflow: visible;
            }
        }
    }
}
</style>
