import { App, defineAsyncComponent } from 'vue';

// Tabs cannot be loaded async as they need to mount and find their children
import Tab from './tabs/Tab.vue';
import Tabs from './tabs/Tabs.vue';

const CallToAction = defineAsyncComponent(() => import('./buttons/CallToAction.vue'));
const MenuButton = defineAsyncComponent(() => import('./buttons/MenuButton.vue'));
const Spinner = defineAsyncComponent(() => import('./loading-spinners/Spinner.vue'));
const ImageUploader = defineAsyncComponent(() => import('./images/ImageUploader.vue'));
const ImageSliderFullView = defineAsyncComponent(() => import('./images/ImageSliderFullView.vue'));

export default async function config(app: App): Promise<void> {
    app.component('Tab', Tab);
    app.component('Tabs', Tabs);
    app.component('CallToAction', CallToAction);
    app.component('MenuButton', MenuButton);
    app.component('Spinner', Spinner);
    app.component('ImageUploader', ImageUploader);
    app.component('ImageSliderFullView', ImageSliderFullView);
}
