<template>
    <template v-if="!keepAlive">
        <div v-if="isActive" class="tab">
            <slot></slot>
        </div>
    </template>
    <template v-else>
        <div v-show="isActive" class="tab">
            <slot></slot>
        </div>
    </template>
</template>

<script lang="ts">
import { defineComponent, inject, onBeforeMount, ref, watch, getCurrentInstance, onBeforeUnmount } from 'vue';
import { TabsState } from './Tabs.vue';

export default defineComponent({
    name: 'Tab',
    props: {
        id: {
            type: String,
            required: false,
        },
        name: {
            type: String,
            required: true,
        },
        tabStyle: {
            type: String,
            required: false,
        },
        keepAlive: {
            type: Boolean,
            default: false,
        },
    },
    emits: {
        'activated': () => true,
    },
    setup(props, { emit }) {
        const self = getCurrentInstance();
        const isActive = ref(false);
        const tabs = inject<TabsState>('TabsProvider')!;

        watch(() => tabs.selectedTab, () => {
            isActive.value = props.name.toLowerCase() === tabs.selectedTab?.toLowerCase();
            if (isActive.value) {
                emit('activated');
            }
        });

        onBeforeMount(() => {
            if (tabs.tabs.indexOf(self) < 0) {
                tabs.tabs.push(self);
            }
            isActive.value = props.name.toLowerCase() === tabs.selectedTab?.toLowerCase();
            if (isActive.value) {
                emit('activated');
            }
        });

        onBeforeUnmount(() => {
            const index = tabs.tabs.indexOf(self);
            if (index >= 0) {
                tabs.tabs.splice(index, 1);
            }
        });

        return {
            isActive,
        };
    },
});
</script>

<style lang="scss" scoped>
</style>
