import {
    AddOrUpdateCustomerContactsRequest,
    AddOrUpdateCustomerNoteRequest,
    CreateCustomerRequest,
    CustomerNoteViewModel, CustomerNoteViewObject,
    CustomerViewModel,
    CustomersViewModel,
    UpdateCustomerRequest,
    UpdateCustomerProductAccessRequest,
    UpdateCustomerProductAccessesRequest,
    CustomersByIdRequest,
    UpdateCustomerProductGroupAccessRequest,
    UpdateCustomerProductGroupAccessesRequest,
    UpdateCustomerUserAccessRequest,
    CustomerAddressViewModel,
    AddOrUpdateCustomerAddressRequest,
    CustomerAddressViewObject,
} from '@/api';
import { ApiBase } from '@/core/api/api.base';
import { apiInstanceFactory } from '@/core/api/api.factory';

const api = apiInstanceFactory();

class CustomerService extends ApiBase {
    public async getCustomer(id: string): Promise<CustomerViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.customerCustomerList({ customerId: id }));
    }

    public async getCustomers(): Promise<CustomersViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.customerCustomersList());
    }

    public async getAvailableCustomersForUser(): Promise<CustomersViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.customerGetAvailableCustomersForUserList());
    }

    public async getCustomersById(request: CustomersByIdRequest): Promise<CustomersViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.customerCustomersByIdCreate({ customerIds: request.customerIds }));
    }

    public async createCustomer(request: CreateCustomerRequest): Promise<CustomerViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.customerCreateCustomerCreate(request));
    }

    public async updateCustomer(request: UpdateCustomerRequest): Promise<CustomerViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.customerUpdateCustomerCreate(request));
    }

    public async deleteCustomer(customerId: string): Promise<CustomerViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.customerDeleteCustomerCreate({ customerId }));
    }

    public async getCustomerNotes({ customerId, skip, take }: { customerId: string; skip: number; take: number; }): Promise<CustomerNoteViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.customerGetNotesList({ customerId, skip, take  }));
    }

    public async addOrUpdateCustomerNote(request: AddOrUpdateCustomerNoteRequest): Promise<CustomerNoteViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.customerAddOrUpdateNoteCreate(request), {
            cancellationKey: 'addOrUpdateCustomerNote',
        });
    }

    public async addOrUpdateCustomerContacts(request: AddOrUpdateCustomerContactsRequest): Promise<CustomerViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.customerAddOrUpdateCustomerContactsCreate(request), {
            cancellationKey: 'addOrUpdateCustomerNote',
        });
    }

    public async deleteCustomerNote(customerId: string, noteId: number): Promise<CustomerNoteViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.customerDeleteNoteDelete({ customerId, noteId }));
    }
    
    public async updateProductAccessForCustomer(request: UpdateCustomerProductAccessRequest): Promise<CustomerViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.customerUpdateProductAccessForCustomerCreate(request));
    }

    public async updateProductAccessForCustomers(request: UpdateCustomerProductAccessesRequest): Promise<CustomersViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.customerUpdateProductAccessForCustomersCreate(request));
    }

    public async updateProductGroupAccessForCustomer(request: UpdateCustomerProductGroupAccessRequest): Promise<CustomerViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.customerUpdateProductGroupAccessForCustomerCreate(request));
    }

    public async updateUserAccessForCustomers(request: UpdateCustomerUserAccessRequest): Promise<CustomerViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.customerUpdateUserAccessForCustomerCreate(request));
    }

    public async updateProductGroupAccessForCustomers(request: UpdateCustomerProductGroupAccessesRequest): Promise<CustomersViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.customerUpdateProductGroupAccessForCustomersCreate(request));
    }

    public async getAddresses(): Promise<CustomerAddressViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.customerGetAddressesList());
    }

    public async getAddressesForCustomer(customerId: string): Promise<CustomerAddressViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.customerGetAddressesForCustomerList({ customerId }));
    }

    public async addOrUpdateAddress(request: AddOrUpdateCustomerAddressRequest): Promise<CustomerAddressViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.customerAddOrUpdateAddressCreate(request));
    }

    public async addOrUpdateAddressForCustomer(request: AddOrUpdateCustomerAddressRequest): Promise<CustomerAddressViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.customerAddOrUpdateAddressForCustomerCreate(request));
    }
}

export default new CustomerService();
