import { App } from 'vue';
import { CustomRequestHeaders } from '@/core/constants';
import { LocalStorageService } from '@/core/storage/storage.service';

declare global {
    interface Window {
        dictionaryMap: Map<string, string>;
        languageCode: string;
        missingLabelsQueue: Array<
        { 
            /** @minLength 1 */
            key: string;
            /** @minLength 1 */
            language: string;
            /** @minLength 1 */
            location: string; 
        }>
    }
}

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $translate: (key: string, ...params: (string | number)[]) => string;
        $tryTranslate: (key: string, ...params: (string | number)[]) => string;
        $getLanguageCode: (translate?: boolean) => string;
    }
}

window.languageCode = LocalStorageService.getItem(CustomRequestHeaders.Language) ?? 'da-dk';
window.dictionaryMap ??= new Map<string, string>();
window.missingLabelsQueue = [];

class Dictionary {
    private initialized: boolean = false;

    public has(key: string, trackMissingLabel: boolean = false) {
        if (window.dictionaryMap.has(key))
            return true;
        
        if (trackMissingLabel && this.initialized) {
            if (!window.missingLabelsQueue.some(x => x.key === key)) {
                window.missingLabelsQueue.push({ key: key, language: window.languageCode, location: window.location.href });
            }
        }
        return false;
    }

    public get(key: string, ...args: any[]): string {
        const translated = window.dictionaryMap.get(key);
        if (translated === undefined) {
            window.missingLabelsQueue.push({ key: key, language: window.languageCode, location: window.location.href });
            console.error(`### Key '${key}' not found in dictionary ###`);
            return `##${key}`;
        }

        const formatted = this.format(translated, args);
        const showLabelsDebug = window.location.href.includes('labels_debug');

        return showLabelsDebug ? `${formatted} (${key})` : formatted;
    }

    private format(input: string, args: string[]): string {
        const res = args.reduce((result, arg, ix) => {
            return result.split(`{${ix}}`).join(arg);
        }, input);
        return res;
    }

    public init(labels: { key: string, text: string }[]) {
        for (let i = 0; i < labels.length; i++) {
            const label = labels[i];
            window.dictionaryMap.set(label.key, label.text);
        }

        this.initialized = true;
    }

    public setActiveLanguageCode(code: string) {
        if (window.languageCode !== code) {
            LocalStorageService.setItem(CustomRequestHeaders.Language, code);
            window.location.reload();
        }
    }

    public addOrUpdateLabel(key: string, text: string) {
        window.dictionaryMap.set(key, text);
    }
}

const dictionary = new Dictionary();

export function dictionaryConfig(app: App): void {
    app.config.globalProperties.$translate = dictionary.get.bind(dictionary);
    app.config.globalProperties.$tryTranslate = (key: string, ...params: (string | number)[]) => dictionary.has(key, false) ? dictionary.get(key, params) : key;
    app.config.globalProperties.$getLanguageCode = (translate = false) => translate ? dictionary.get('Language.' + window.languageCode) : window.languageCode;
}

export default dictionary;
