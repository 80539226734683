class SoundService {
    public play(sound: 'notification') {
        try {
            import(`../../core/sounds/${sound}.mp3`).then(
                (file) => {
                    const audio = new Audio(file.default);
                    audio.play();
                },
            );
        } catch (e) {
            console.log(e);
        }
    }
}

export default new SoundService(); 