import { CustomerAddressViewObject, CustomerLockViewObject, CustomerUserAccessViewObject, CustomerViewObject, GroupChatMessageViewObject, GroupChatViewObject, HubConnectionViewObject, OrderViewObject, ProductCategoryViewObject, ProductCustomerAssetViewObject, ProductGroupLockViewObject, ProductGroupViewObject, ProductLockViewObject, ProductViewObject, UserViewObject } from '@/api';
import { defaultReconnectPolicy, SignalRClient } from '@/core/signalr/signalr.client';
import { isDevMode } from '@/core/infrastructure/environment';
import { LogLevel } from '@microsoft/signalr';
import bus from '@/core/bus';

const SIGNALR_URL = import.meta.env.VITE_API_BASE + '/hub';
const SIGNALR_LOGLEVEL = isDevMode() ? LogLevel.Information : LogLevel.Error;

export class ServerHub extends SignalRClient {
    constructor() {
        super(SIGNALR_URL, SIGNALR_LOGLEVEL, defaultReconnectPolicy, false);

        // CUSTOMERS
        this.addEvent('CustomerCreated', (customer: CustomerViewObject) => {
            bus.emit('CustomerCreated', customer);
        });
        
        this.addEvent('CustomerUpdated', (customer: CustomerViewObject) => {
            bus.emit('CustomerUpdated', customer);
        });
        
        this.addEvent('CustomersUpdated', (customers: CustomerViewObject[]) => {
            bus.emit('CustomersUpdated', customers);
        });
        
        this.addEvent('CustomerRemoved', (customerId: string) => {
            bus.emit('CustomerRemoved', customerId);
        });

        this.addEvent('CustomerAddressUpdated', (address: CustomerAddressViewObject) => {
            bus.emit('CustomerAddressUpdated', address);
        });
        
        this.addEvent('CustomerAddressRemoved', (addressId: string) => {
            bus.emit('CustomerAddressRemoved', addressId);
        });
        
        this.addEvent('LockCustomer', (lock: CustomerLockViewObject) => {
            if (lock.connectionId === this.connectionId.value) return;
            bus.emit('LockCustomer', lock);
        });
        
        this.addEvent('UnlockCustomer', (lock: CustomerLockViewObject) => {
            bus.emit('UnlockCustomer', lock);
        });

        // PRODUCTS
        this.addEvent('ProductCreated', (product: ProductViewObject) => {
            bus.emit('ProductCreated', product);
        });
                
        this.addEvent('ProductUpdated', (product: ProductViewObject) => {
            bus.emit('ProductUpdated', product);
        });
                
        this.addEvent('ProductsUpdated', (products: ProductViewObject[]) => {
            bus.emit('ProductsUpdated', products);
        });
                
        this.addEvent('ProductRemoved', (productId: string) => {
            bus.emit('ProductRemoved', productId);
        });
                
        this.addEvent('LockProduct', (lock: ProductLockViewObject) => {
            if (lock.connectionId === this.connectionId.value) return;
            bus.emit('LockProduct', lock);
        });
                
        this.addEvent('UnlockProduct', (lock: ProductLockViewObject) => {
            bus.emit('UnlockProduct', lock);
        });

        this.addEvent('ProductCustomerAssetsUpdated', (productId: string, assets: ProductCustomerAssetViewObject[]) => {
            bus.emit('ProductCustomerAssetsUpdated', productId, assets);
        });

        // PRODUCT GROUPS
        this.addEvent('ProductGroupCreated', (product: ProductGroupViewObject) => {
            bus.emit('ProductGroupCreated', product);
        });
                        
        this.addEvent('ProductGroupUpdated', (product: ProductGroupViewObject) => {
            bus.emit('ProductGroupUpdated', product);
        });
                        
        this.addEvent('ProductGroupsUpdated', (products: ProductGroupViewObject[]) => {
            bus.emit('ProductGroupsUpdated', products);
        });
                        
        this.addEvent('ProductGroupRemoved', (productId: string) => {
            bus.emit('ProductGroupRemoved', productId);
        });
                        
        this.addEvent('LockProductGroup', (lock: ProductGroupLockViewObject) => {
            if (lock.connectionId === this.connectionId.value) return;
            bus.emit('LockProductGroup', lock);
        });
                        
        this.addEvent('UnlockProductGroup', (lock: ProductGroupLockViewObject) => {
            bus.emit('UnlockProductGroup', lock);
        });
        
        // PRODUCT CATEGORIES
        this.addEvent('ProductCategoryCreated', (category: ProductCategoryViewObject) => {
            bus.emit('ProductCategoryCreated', category);
        });
                        
        this.addEvent('ProductCategoryUpdated', (category: ProductCategoryViewObject) => {
            bus.emit('ProductCategoryUpdated', category);
        });
                        
        this.addEvent('ProductCategoriesUpdated', (categoryies: ProductCategoryViewObject[]) => {
            bus.emit('ProductCategoriesUpdated', categoryies);
        });
                        
        this.addEvent('ProductCategoryRemoved', (categoryId: string) => {
            bus.emit('ProductCategoryRemoved', categoryId);
        });
                              
        this.addEvent('ProductCategoriesRemoved', (categoryIds: string[]) => {
            bus.emit('ProductCategoriesRemoved', categoryIds);
        });              

        // CHAT
        this.addEvent('ChatMessageReceived', (message: GroupChatMessageViewObject) => {
            bus.emit('ChatMessageReceived', message);
        });
        
        this.addEvent('GroupChatCreatedOrUpdated', (chat: GroupChatViewObject) => {
            bus.emit('GroupChatCreatedOrUpdated', chat);
        });
        
        this.addEvent('GroupChatRemoved', (chatId: string) => {
            bus.emit('GroupChatRemoved', chatId);
        });

        // USERS
        this.addEvent('UserCreated', (user: UserViewObject) => {
            bus.emit('UserCreated', user);
        });

        this.addEvent('UserUpdated', (user: UserViewObject) => {
            bus.emit('UserUpdated', user);
        });
                                
        this.addEvent('UsersUpdated', (users: UserViewObject[]) => {
            bus.emit('UsersUpdated', users);
        });
                                
        this.addEvent('UserRemoved', (userId: string) => {
            bus.emit('UserRemoved', userId);
        });

        this.addEvent('OnConnected', (connection: HubConnectionViewObject) => {
            bus.emit('OnConnected', connection);
        });

        this.addEvent('OnConnectionUpdate', (connection: HubConnectionViewObject) => {
            bus.emit('OnConnectionUpdate', connection);
        });

        this.addEvent('OnDisconnected', (userId: string, connectionId: string) => {
            bus.emit('OnDisconnected', userId, connectionId);
        });

        this.addEvent('UsersAddedToCustomer', (userCustomerAccesses: CustomerUserAccessViewObject[], customerId: string) => {
            bus.emit('UsersAddedToCustomer', userCustomerAccesses, customerId);
        });

        this.addEvent('UsersRemovedFromCustomer', (userIds: string[], customerId: string) => {
            bus.emit('UsersRemovedFromCustomer', userIds, customerId);
        });

        // ORDERS
        this.addEvent('OrderUpdated', (order: OrderViewObject) => {
            bus.emit('OrderUpdated', order);
        });

        this.addEvent('OrderRemoved', (orderId: string) => {
            bus.emit('OrderRemoved', orderId);
        });
    }

    public async toggleIsTyping(chatId: string | null | undefined, members: string[]): Promise<void> {
        await this.connection.send('ToggleIsTyping', chatId, members);
    }

    public async lockCustomer(customerId: string, lockReason: string): Promise<void> {
        await this.connection.invoke('LockCustomer', customerId, lockReason);
    }

    public async unlockCustomer(customerId: string): Promise<void> {
        await this.connection.send('UnlockCustomer', customerId);
    }
}

export default new ServerHub();
