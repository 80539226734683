import { createApp } from 'vue';
import App from './App.vue';
import { App as AppType } from 'vue';
import router from './router/router.index';
import './styling/main.scss';

import coreConfig from '@/core';
import projectConfig from '@/project';
import componentsConfig from '@/components';
import dictionary from '@/core/dictionary/dictionary';
import loadingScreen from './loadingScreen';
import authenticationService from './core/authentication/authentication.service';
import dictionaryApiService from './core/api/controllers/dictionaryApi.service';

import { LocalStorageService } from './core/storage/storage.service';
import { RouteNames } from './router/router.routes';

let app: AppType;

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $routes: typeof RouteNames;
    }
}

(async function() {
    const theme = LocalStorageService.getItem('website-theme');
    if (theme) {
        document.body.classList.add(theme);
    }

    loadingScreen.show();

    authenticationService.setEmployeesOnly(true);

    const result = await dictionaryApiService.getAdminDictionaryLabelsForArea();

    if (result?.labels?.length) {
        dictionary.init(result.labels);
    }
        
    await authenticationService.tryRefreshSession();

    app = createApp(App);

    app.config.warnHandler = (e) => {
        if (e.startsWith('onMounted is called when there is no active component instance to be associated with.')) // Silences a weird onMounted warning thrown by the BreakPoints component even though it works flawlessly <.<
            return;

        console.warn(e);
    };

    await coreConfig(app, dictionaryApiService.markAdminLabelAsMissingRequest.bind(dictionaryApiService));
    await projectConfig(app);
    await componentsConfig(app);

    app.config.globalProperties.$routes = RouteNames;
    
    app.use(router);

    await router.isReady();
    
    try {
        app.mount('#app');
    } catch (e) {
        console.error(e);
        window.location.reload();
    }

    loadingScreen.hide();
})();

export { app };
