import { UserRole } from '@/api';
import { RouteNames } from '@/router/router.routes';
import { Ref, computed } from 'vue';
import dictionary from '@/core/dictionary/dictionary';
import { usersStore } from '@/store/users/users.store';

export interface NavigationItem {
    name: string;
    dataUpdater?: {
        interval: number;
        displayValue: Ref<string>;
        updater: () => Promise<any>;
    },
    displayValue?: Ref<string>;
    icon?: {
        name: string;
        offsetX?: number;
        offsetY?: number;
    };
    role?: UserRole;
    route?: { name: RouteNames, hash?: string },
    children?: Array<Omit<NavigationItem, 'children'>>
}

export const useNavigationItems = (): Array<NavigationItem> => {
    return [
        {
            name: dictionary.get('Navigation.Dashboard'),
            icon: {
                name: 'view-dashboard',
            },
            route: {
                name: RouteNames.DASHBOARD,
            },
            role: UserRole.Administrator,
        },
        {
            name: dictionary.get('Navigation.Customers'),
            displayValue: computed(() =>{
                const count = usersStore.users.value
                    .filter(x => x.activeConnections?.some(c => !!c.customerId))
                    .length;

                return count > 0 
                    ? `<span class="text-green-500 ml-3">(${count})</span>` 
                    : `<span class="text-gray-400 ml-3">(${count})</span>`;
            }),
            icon: {
                name: 'domain',
            },
            route: {
                name: RouteNames.CUSTOMERS,
            },
            role: UserRole.Administrator,
        },
        {
            name: dictionary.get('Navigation.Users'),
            displayValue: computed(() =>{
                const count = usersStore.users.value.filter(x => !!x.activeConnections?.length).length;
                return count > 0 
                    ? `<span class="text-green-500 ml-3">(${count})</span>` 
                    : `<span class="text-gray-400 ml-3">(${count})</span>`;
            }),
            icon: {
                name: 'account-group',
            },
            route: {
                name: RouteNames.USERS,
            },
            role: UserRole.Administrator,
        },
        {
            name: dictionary.get('Navigation.Products'),
            icon: {
                name: 'spray-bottle',
            },
            role: UserRole.Administrator,
            children: [
                {
                    name: 'Oversigt',
                    icon: {
                        name: 'database-search',
                    },
                    route: {
                        name: RouteNames.PRODUCTS_OVERVIEW,
                    },
                    role: UserRole.Administrator,
                },
                {
                    name: 'Grupper',
                    icon: {
                        name: 'package-variant-closed',
                    },
                    route: {
                        name: RouteNames.PRODUCTS_GROUPS,
                    },
                    role: UserRole.Administrator,
                },
                {
                    name: 'Kategorier',
                    icon: {
                        name: 'shape-plus',
                    },
                    route: {    
                        name: RouteNames.PRODUCTS_CATEGORIES,
                    },
                    role: UserRole.Administrator,
                },
            ],
        },
        {
            name: 'Ordre',
            icon: {
                name: 'clipboard-check-outline',
            },
            route: {
                name: RouteNames.ORDER_HISTORY,
            },
            role: UserRole.Administrator,
        },
        {
            name: dictionary.get('Navigation.Dictionary'),
            icon: {
                name: 'book-open',
            },
            role: UserRole.Administrator,
            children: [
                {
                    name: 'Sprog',
                    icon: {
                        name: 'web',
                    },
                    route: {
                        name: RouteNames.DICTIONARY_LANGUAGES,
                    },
                    role: UserRole.Administrator,
                },
                {
                    name: 'Labels',
                    icon: {
                        name: 'truck',
                    },
                    route: {
                        name: RouteNames.DICTIONARY_LABELS,
                    },
                    role: UserRole.Administrator,
                },
            ],
        },
        // {
        //     name: dictionary.get('Navigation.Administration'),
        //     icon: {
        //         name: 'lock',
        //     },
        //     role: UserRole.Administrator,
        //     children: [
        //         {
        //             name: 'Indstillinger',
        //             icon: {
        //                 name: 'cog',
        //             },
        //             route: {
        //                 name: RouteNames.ADMINISTRATION_SETTINGS,
        //             },
        //             role: UserRole.Administrator,
        //         },
        //     ],
        // },
    ];
};
