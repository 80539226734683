import { userStore } from '@/core/store/user/user.store';
import { minutesToMilliseconds } from 'date-fns';
import { createRouter, createWebHistory, RouteLocationNormalized } from 'vue-router';
import { RouteNames, routes, RouteMetaExtended } from './router.routes';
import { isProdMode } from '@/core/infrastructure/environment';
import dictionary from '@/core/dictionary/dictionary';
import notificationsService from '@/core/notifications/notifications.service';
import dictionaryApiService from '@/core/api/controllers/dictionaryApi.service';

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    },
});

const dictionaryAreasVisited: Record<string, Date> = {};

const ensureDictionaryIsReadyForRouteArea = async(to: RouteLocationNormalized) => {
    const meta = to.meta as RouteMetaExtended;
    const area = meta.area;

    if (!area)
        return;

    const now = new Date();
    const lastUpdated = dictionaryAreasVisited[area];

    if (lastUpdated && (now.getTime() - lastUpdated.getTime()) < minutesToMilliseconds(5))
        return;

    const result = await dictionaryApiService.getAdminDictionaryLabelsForArea(area);

    if (result?.labels?.length) {
        for (const label of result.labels) {
            window.dictionaryMap.set(label.key, label.text);
        }
    }

    dictionaryAreasVisited[area] = now;
};

router.beforeEach(async(to, from, next) => {
    const meta = to.meta as RouteMetaExtended;
    const isLoggedIn = userStore.isLoggedIn;

    if ((to.name === RouteNames.AUTH_LOGIN || 
        to.name === RouteNames.AUTH_FORGOT_PASSWORD ||
        to.name === RouteNames.AUTH_RESET_PASSWORD) && isLoggedIn.value) {
        await ensureDictionaryIsReadyForRouteArea(to);
        next({ name: RouteNames.DASHBOARD, params: to.params, query: to.query });
        return;
    } else if (!meta.allowAnonymous && !isLoggedIn.value) {
        await ensureDictionaryIsReadyForRouteArea(to);
        next({ name: RouteNames.AUTH_LOGIN, params: to.params, query: { to: to.path, ...to.query } });
        return;
    } else if (meta.role && !userStore.isStaff && !userStore.hasRole(meta.role)) {
        notificationsService.notify({
            title: 'Ingen adgang',
            message: 'Du har ikke adgang til at tilgå denne side',
            type: 'warning',
            duration: 5000,
        });

        await ensureDictionaryIsReadyForRouteArea(to);
        next({ name: RouteNames.DASHBOARD, params: to.params });
        return;
    }

    await ensureDictionaryIsReadyForRouteArea(to);
    next();
});

router.afterEach((to, from) => {
    // const meta = to.meta as RouteMetaExtended;

    let title = to.name?.toString();

    if (title) {
        const pageTitleKey = 'Page.Title.' + title;
        if (dictionary.has(pageTitleKey, true)) {
            title = dictionary.get(pageTitleKey);
        }

        document.title = `${title} - Socco`;
    } else {
        document.title = 'Socco';
    }

    window.document.body.scrollTo({ top: 0, behavior: 'smooth' });
});

const isProduction = isProdMode();
if (isProduction) {
    router.onError((e, to) => {
        if (e && typeof e.message === 'string') {
            if (e.message.startsWith('Failed to fetch dynamically imported module')) {
                // After a deployment, users must re-fresh the page to update the async components locations
                window.location.reload();
            }
        }
    });
}

console.log(`SOCCO APP ENVIRONMENT: ${isProduction ? 'PRODUCTION' : 'DEVELOPMENT'}`);

export default router;
