import { computed, ComputedRef } from 'vue';
import { CustomerUserAccessViewObject, HubConnectionViewObject, UserViewObject } from '@/api';
import bus from '@/core/bus';
import { Store } from '@/core/store/store';
import { mergeObject } from '@/core/util/mergeObjects';
import usersApiService from '@/core/api/controllers/usersApi.service';

type Hooks = '';

interface UserState extends Record<string, unknown> {
    users: Map<string, UserViewObject>;
}

class UsersStore extends Store<UserState, Hooks> {
    protected data(): UserState {
        return {
            users: new Map<string, UserViewObject>(),
        };
    }

    public async getUsers() {
        const result = await usersApiService.getUsers();
        if (result) {
            this.addOrUpdateUsers(result.users);
            this.updateObjectLastUpdateTimer(this.state.users);
        }
    }

    public get users(): ComputedRef<UserViewObject[]> {
        this.staleWhileRevalidate<Map<string, UserViewObject> | void>({
            target: this.state.users,
            action: async() => await this.getUsers(),
            cacheTimeMs: 30000,
        });

        return computed(() => Array.from(this.state.users.values()));
    }

    public get activeUsers(): ComputedRef<UserViewObject[]> {
        return computed(() => this.users.value.filter(x => !x.deletedSince && !x.blocked));
    }

    public get usersMap() {
        this.staleWhileRevalidate<Map<string, UserViewObject> | void>({
            target: this.state.users,
            action: async() => await this.getUsers(),
            cacheTimeMs: 30000,
        });
        
        return computed(() => this.state.Users);
    }

    public addOrUpdateUser(user: UserViewObject) {
        const existing = this.state.users.get(user.id);
        if (existing) {
            mergeObject(existing, user);
        } else {
            this.state.users.set(user.id, user);
        }
    }

    public addOrUpdateUsers(users: UserViewObject[]) {
        this.state.users = new Map(users.map(User => [User.id, User])); 
    }

    public addOrUpdateUserConnection(connection: HubConnectionViewObject) {
        const user = this.state.users.get(connection.userId);
        if (user) {
            if (!user.activeConnections?.length) {
                user.activeConnections = [connection];
            } else {
                const existingConnection = user.activeConnections.find(x => x.connectionId === connection.connectionId);
                if (existingConnection) {
                    mergeObject(existingConnection, connection);
                } else {
                    user.activeConnections.push(connection);
                }
            }
        }
    }

    public removeUserConnection(userId: string, connectionId: string) {
        const user = this.state.users.get(userId);
        if (user) {
            if (user.activeConnections?.length) {
                const index = user.activeConnections.findIndex(x => x.connectionId === connectionId);
                if (index >= 0) {
                    user.activeConnections.splice(index, 1);
                }
            }
        }
    }

    public usersAddedToCustomer(userCustomerAccesses: CustomerUserAccessViewObject[], _customerId: string) {
        for (const access of userCustomerAccesses) {
            const user = this.state.users.get(access.userId);
            if (!user)
                continue;
        
            const existing = user.customerAccesses.find(x => x.customerId === _customerId);
            if (existing) {
                existing.allowUploadingSecurityFiles = access.allowUploadingSecurityFiles;
            } else {
                user.customerAccesses.push({ customerId: _customerId, allowUploadingSecurityFiles: access.allowUploadingSecurityFiles });
            }
        }
    }

    public usersRemovedFromCustomer(userIds: string[], _customerId: string) {
        for (const userId of userIds) {
            const user = this.state.users.get(userId);
            if (!user)
                continue;
        
            const index = user.customerAccesses.findIndex(x => x.customerId === _customerId);
            if (index >= 0) {
                user.customerAccesses.splice(index, 1);
            }
        }
    }

    public removeUser(userId: string) {
        return this.state.users.delete(userId);
    }

    public clear() {
        this.state.users.clear();
    }

    constructor() {
        super();

        bus.on('UserUpdated', (user) => this.addOrUpdateUser(user));
        bus.on('UsersUpdated', (user) => this.addOrUpdateUsers(user));
        bus.on('UserCreated', (user) => this.addOrUpdateUser(user));
        bus.on('UserRemoved', (userId) => this.removeUser(userId));
        bus.on('OnConnected', (connection) => this.addOrUpdateUserConnection(connection));
        bus.on('OnDisconnected', (userId, connectionId) => this.removeUserConnection(userId, connectionId));
        bus.on('OnConnectionUpdate', (user) => this.addOrUpdateUserConnection(user));
        bus.on('UsersAddedToCustomer', (userCustomerAccesses, customerId) => this.usersAddedToCustomer(userCustomerAccesses, customerId));
        bus.on('UsersRemovedFromCustomer', (userIds, customerId) => this.usersRemovedFromCustomer(userIds, customerId));

        bus.on('LOGGED_OUT', () => {
            this.clear();
        });
    }
}

export const usersStore = new UsersStore();
