import { apiInstanceFactory } from '@/core/api/api.factory';
import { ApiBase } from '@/core/api/api.base';
import { AddOrUpdateAllowableIpsRequest, CreateUserRequest, UpdateCustomerAccessRequest, UpdateCustomerAccessesRequest, UpdateUserRequest, UserLoginHistoryViewModel, UserViewModel, UsersByIdRequest, UsersViewModel } from '@/api';
import { AxiosProgressEvent } from 'axios';

const api = apiInstanceFactory();

class UsersService extends ApiBase {
    public async updateProfileImage(formFile: File, callback?: (event: AxiosProgressEvent, loadPercentage: number) => void): Promise<UserViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.usersUpdateProfileImageUpdate({
            formFile,
        }, {
            headers: undefined!,
            onUploadProgress: (event: AxiosProgressEvent) => {
                callback && callback(event, this.calculateLoadPercentage(event));
            },
        }));
    }
    
    public async getUser(userId: string): Promise<UserViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.usersGetUserByIdList({ userId }));
    }

    public async getUsers(): Promise<UsersViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.usersGetUsersCreate());
    }

    public async getEmployees(): Promise<UsersViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.usersGetEmployeesCreate());
    }

    public async getUsersById(request: UsersByIdRequest): Promise<UsersViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.usersGetUsersByIdCreate(request));
    }

    public async getLoginHistoryForUser(userId: string): Promise<UserLoginHistoryViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.usersGetLoginHistoryForUserCreate({ userId }));
    }

    public async getUserLoginHistory(): Promise<UserLoginHistoryViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.usersGetUserLoginHistoryCreate());
    }
    
    public async createUser(request: CreateUserRequest): Promise<UserViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.usersCreateUserCreate(request));
    }

    public async updateUser(request: UpdateUserRequest): Promise<UserViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.usersUpdateUserCreate(request));
    }

    public async deleteUser(userId: string): Promise<UserViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.usersDeleteUserCreate({ userId }));
    }

    public async restoreUser(userId: string): Promise<UserViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.usersRestoreUserCreate({ userId }));
    }

    public async blockUser(userId: string): Promise<UserViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.usersBlockUserCreate({ userId }));
    }

    public async unblockUser(userId: string): Promise<UserViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.usersUnblockUserCreate({ userId }));
    }

    public async resetPasswordForUser(userId: string, password?: string): Promise<UserViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.usersResetPasswordForUserCreate({ userId, password }));
    }

    public async resendWelcomeMail(userId: string): Promise<UserViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.usersResendWelcomeMailCreate({ userId }));
    }

    public async resendWelcomeSms(userId: string): Promise<UserViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.usersResendWelcomeSmsCreate({ userId }));
    }

    public async updateAllowableIpsForUser(request: AddOrUpdateAllowableIpsRequest): Promise<UserViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.usersUpdateAllowableIpsForUserCreate(request));
    }

    public async updateCustomerAccessesForUser(request: UpdateCustomerAccessRequest): Promise<UserViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.usersUpdateCustomerAccessForUserCreate(request));
    }

    public async updateCustomerAccessesForUsers(request: UpdateCustomerAccessesRequest): Promise<UsersViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.usersUpdateCustomerAccessForUsersCreate(request));
    }
}

export default new UsersService();
