import { App, MaybeRef, unref } from 'vue';

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $unref: (maybeRef: MaybeRef<string>) => string;
    }
}

export default function config(app: App): void {
    app.config.globalProperties.$unref = (maybeRef: MaybeRef<string>) => unref(maybeRef);
}
