import { AddressViewObject } from '@/api';
import { mergeWith } from 'lodash-es';

function customizer<T = Record<string, any>>(objValue: T, srcValue: T) {
    if (Array.isArray(objValue)) {
        return srcValue;
    }

    return srcValue;
}

export function mergeObject<T = Record<string, any>>(target: T, value: T): T {
    return mergeWith(target, value, customizer);
}

export function mergeAddress(a: AddressViewObject, b: AddressViewObject, skipIfEmpty = false) {
    mergeObject(a, b);
}

export function resetAddress(a: AddressViewObject) {
    a.street = '';
    a.postalCode = '' as any;
    a.city = '';
    a.country = 'Danmark';
    a.floor = null;
    a.door = null;
    a.addressId = '';
}

export function formatAddress(a: AddressViewObject) {
    return `${a.street}, ${a.postalCode} ${a.city}, ${a.country}`;
}