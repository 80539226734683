import { Api, ApiConfig } from '@/api';
import { CustomHeaders, CustomRequestHeaders } from '@/core/constants';
import { useLocalStorage } from '@vueuse/core';
import { LocalStorageKeys } from '@/project/localStorageKeys';
import HttpStatus from 'http-status-codes';
import axios, { InternalAxiosRequestConfig, AxiosResponse } from 'axios';
import bus from '@/core/bus';
import serverHubClient from '@/core/signalr/serverHub.client';

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const authenticationToken = useLocalStorage<string>(LocalStorageKeys.TOKEN, '');
const activeCustomerId = useLocalStorage<string>(LocalStorageKeys.CUSTOMER, '');

const responseHandler = async(response: AxiosResponse): Promise<AxiosResponse<any>> => {
    const isTokenInvalid = response?.headers[CustomHeaders.TokenExpired];
    if (isTokenInvalid) {
        authenticationToken.value = '';
        bus.emit('LOGGED_OUT');
        return response;
    }

    const token = response?.headers[CustomHeaders.Token] as string;
    if (token) {
        authenticationToken.value = token;
    }

    if (response.status === HttpStatus.UNAUTHORIZED && token) {
        // We got a 401 but a new token has been provided, retry the rejected request
        return await axios.request(response.config);
    }

    return response;
};

const requestHandler = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    if (config.headers) {
        if (authenticationToken.value) {
            config.headers['Authorization'] = `Bearer ${authenticationToken.value}`;
        }

        if (activeCustomerId.value) {
            config.headers[CustomHeaders.Customer] = activeCustomerId.value;
        }

        if (window.languageCode) {
            config.headers[CustomRequestHeaders.Language] = window.languageCode;
        }

        if (serverHubClient.connectionId.value) {
            config.headers[CustomRequestHeaders.SignalRConnectionId] = serverHubClient.connectionId.value;
        }

        config.headers[CustomRequestHeaders.Timezone] = timeZone;
    }

    return config;
};

export const apiInstanceFactory = ({ format, ...axiosConfig }: ApiConfig<unknown> = { headers: undefined! }): Api<unknown> => {
    const api = new Api({
        baseURL: import.meta.env.VITE_API_BASE,
        secure: true,
        format,
        ...axiosConfig,
    });

    api.instance.interceptors.request.use(requestHandler);
    api.instance.interceptors.response.use(responseHandler);

    return api;
};
