import * as signalR from '@microsoft/signalr';
import { LocalStorageService } from '../storage/storage.service';
import bus from '@/core/bus';
import { LocalStorageKeys } from '@/project/localStorageKeys';
import { HttpTransportType } from '@microsoft/signalr';
import { computed, ComputedRef, ref } from 'vue';
import { useLocalStorage } from '@vueuse/core';
import { createURLWithParams } from '../constants';

const TIMEOUT_BETWEEN_RECONNECT = 5000;
const TIME_ZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;
const activeCustomerId = useLocalStorage<string>(LocalStorageKeys.CUSTOMER, '');

export const defaultReconnectPolicy: signalR.IRetryPolicy = {
    nextRetryDelayInMilliseconds: retryContext => {
        return TIMEOUT_BETWEEN_RECONNECT;
    },
};

export abstract class SignalRClient {
    protected connection!: signalR.HubConnection;
    private _isConnected = ref(false);
    private _connectionId = ref<string | undefined>('');

    private async setupConnection(autoStart: boolean) {
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(createURLWithParams(this.url, { 
                timezone: TIME_ZONE,
                customerId: activeCustomerId.value || undefined,
            }), {
                accessTokenFactory: () => LocalStorageService.getItem(LocalStorageKeys.TOKEN) || '',
                skipNegotiation: false,
                transport: HttpTransportType.WebSockets,
            })
            .withAutomaticReconnect(this.reconnectPolicy)
            .configureLogging(this.logLevel)
            .build();

        if (autoStart) {
            this.start();
        }

        this.connection.onreconnected((connectionId) => {
            this._isConnected.value = true;
            this._connectionId.value = connectionId;
            bus.emit('SIGNALR_RECONNECT', connectionId);
        });

        this.connection.onreconnecting(() => {
            this._isConnected.value = false;
        });
    }

    constructor(private url: string, private logLevel: signalR.LogLevel, private reconnectPolicy: signalR.IRetryPolicy = defaultReconnectPolicy, private autoStart = true) {
        this.setupConnection(autoStart);
    }

    public async reconnect(newConnection = false): Promise<void> {
        await this.stop();

        if (newConnection) {
            this.setupConnection(false);
        }

        await this.start();
    }

    public get connectionId(): ComputedRef<string | undefined> {
        return computed(() => this._connectionId.value);
    }

    public get isConnected(): ComputedRef<boolean> {
        return computed(() => this._isConnected.value);
    }

    public async start(): Promise<void> {
        try {
            await this.connection.start();
            this._isConnected.value = true;
            this._connectionId.value = this.connection?.connectionId ?? undefined;
            bus.emit('SIGNALR_RECONNECT', this._connectionId.value);
        } catch (e) {
            console.error(e);
        }
    }

    public async stop(): Promise<void> {
        try {
            await this.connection.stop();
            this._isConnected.value = false;
            this._connectionId.value = undefined;
            bus.emit('SIGNALR_RECONNECT');
        } catch (e) {
            console.error(e);
        }
    }

    public addEvent(event: string, callback: (...args: any[]) => void): void {
        this.connection.on(event, callback);
    }

    public removeEvent(event: string, callback: (...args: any[]) => void): void {
        this.connection.off(event, callback);
    }

    /*
         Returns a promise that is resolved when the client has sent the invocation to the server,
         or an error occurred. The server may still be handling the invocation when the promise resolves.
     */
    public async sendMessage(methodName: string, ...args: any[]): Promise<void> {
        await this.connection.send(methodName, args);
    }

    /*
        Returns a promise that is resolved when the server has finished invoking the method (or an error occurred).
        In addition, the Invoke promise can receive a result from the server method, if the server returns a result.
    */
    public async invoke<T>(methodName: string, ...args: any[]): Promise<T> {
        return await this.connection.invoke<T>(methodName, args);
    }
}
