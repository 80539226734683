let interval: number | undefined;

export function startDictionaryMissingLabelsQueue(caller:  (request: { 
    /** @minLength 1 */
    key: string;
    /** @minLength 1 */
    language: string;
    /** @minLength 1 */
    location: string; 
}) => Promise<boolean | undefined>) {
    clearInterval(interval);
    
    interval = setInterval(async() => {
        for (let i = window.missingLabelsQueue.length - 1; i >= 0; i--) {
            await caller(window.missingLabelsQueue[i]);
            window.missingLabelsQueue.splice(i, 1);
        }
    }, 5000);
}