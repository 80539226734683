<template>
    <div v-if="hasAccess" class="nav-item-container" :class="{ active: active && item.children }">
        <component :is="!item.route ? 'button' : 'router-link'"
                   class="nav-item"
                   :to="item.route && { name: item.route.name, hash: item.route.hash }"
                   @click="toggleActive()">
            <Transition
                mode="out-in"
                :enter-active-class="enterActiveClass"
                :leave-active-class="leaveActiveClass">
                <div v-if="!iconOnly" class="flex flex-row flex-1 items-center">
                    <CIcon v-if="item.icon"
                           class="icon-left"
                           :name="item.icon.name"
                           height="18"
                           width="18"
                           fill/>
                    <p class="nav-item-title">
                        <span>{{ item.name }}</span>
                        <span v-if="item.dataUpdater?.displayValue.value" v-html="item.dataUpdater.displayValue.value">
                        </span>
                        <span v-else-if="item.displayValue" v-html="item.displayValue.value">
                        </span>
                    </p>
                    <CIcon v-if="item.children"
                           class="icon-right"
                           name="chevron-down"
                           height="18"
                           width="18"
                           fill/>
                </div>
                <div v-else>
                    <CIcon v-if="item.icon"
                           :name="item.icon.name"
                           :title="item.name"
                           height="25"
                           width="25"
                           fill/>
                </div>
            </Transition>
        </component>

        <Transition v-if="item.children"
                    name="accordion-anim"
                    @enter="setFixedHeight"
                    @after-enter="setAutoHeight"
                    @before-leave="setFixedHeight">
            <div v-if="active" class="nav-item-dropdown">
                <router-link v-for="child in item.children"
                             :key="child.name"
                             class="nav-item nav-item-child"
                             :class="{ iconOnly: iconOnly }"
                             :to="getRoute(child)">
                    <template v-if="!iconOnly">
                        <CIcon v-if="child.icon"
                               class="icon-left"
                               :name="child.icon.name"
                               height="16"
                               width="16"
                               fill/>
                        <p class="nav-item-title">
                            <span>{{ child.name }}</span>
                            <span v-if="child.dataUpdater?.displayValue.value" v-html="child.dataUpdater.displayValue.value">
                            </span>
                            <span v-else-if="child.displayValue" v-html="child.displayValue.value">
                            </span>
                        </p>
                    </template>
                    <template v-else>
                        <CIcon v-if="child.icon"
                               :name="child.icon.name"
                               :title="child.name"
                               height="25"
                               width="25"
                               fill/>
                    </template>
                </router-link>
            </div>
        </Transition>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, PropType } from 'vue';
import { RouteNames } from '@/router/router.routes';
import { userStore } from '@/core/store/user/user.store';
import { useIntervalFn, useToggle } from '@vueuse/core';
import { NavigationItem } from '../navigationItems';
import { useAnimation } from '@/core/animation/animateComposable';
import { RouteLocationRaw } from 'vue-router';
import router from '@/router/router.index';

export default defineComponent({
    name: 'LeftMenuNavigationItem',
    props: {
        item: {
            type: Object as PropType<NavigationItem>,
            required: true,
        },
        iconOnly: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const { enterActiveClass, leaveActiveClass } = useAnimation({ type: 'fade' });
        const [active, toggleActive] = useToggle();

        const setFixedHeight = (el) => {
            el.style.height = el.scrollHeight + 'px';
        };

        const setAutoHeight = (el) => {
            el.style.height = 'auto';
        };

        const validateExpandedState = () => {
            setTimeout(() => {
                const activeRoute = router.currentRoute.value.name as RouteNames;
                active.value = props.item.route?.name === activeRoute || (activeRoute && !!props.item.children?.some(x => x.route?.name && activeRoute?.startsWith(x.route.name)));
            });
        };

        onMounted(validateExpandedState);

        const hasAccess = computed(() => {
            return !props.item.role || userStore.hasRole(props.item.role);
        });

        if (props.item.dataUpdater) {
            useIntervalFn(async() => await props.item.dataUpdater!.updater(), props.item.dataUpdater.interval, { immediateCallback: true });
        }

        return {
            enterActiveClass, leaveActiveClass,
            active, toggleActive,
            setFixedHeight, setAutoHeight,
            hasAccess,

            getRoute: (item: NavigationItem): RouteLocationRaw => {
                return item.route && { name: item.route.name, hash: item.route.hash } as any;
            },
        };
    },
});
</script>

<style lang="scss" scoped>
.nav-item-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: background-color 200ms ease-in-out;
    white-space: nowrap;
    font-size: theme('fontSize.12');
    flex-shrink: 0;

    &.active {
        // background-color: color-mod(theme("colors.navItemActive") a(0.25));

        .nav-item .icon-right {
            transform: rotate(-180deg);
        }
    }

    .nav-item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 0 auto;
        padding: 7px 15px;

        .icon-left {
            margin-right: 10px;
            flex-shrink: 0;
        }

        .icon-right {
            margin-left: auto;
            margin-right: -5px;
            flex-shrink: 0;
        }

        position: relative;

        &:hover,
        &:active,
        &.router-link-active,
        &.router-link-exact-active {
            background-color: theme("colors.navItemActive");

            &.nav-item-child {
                background-color: theme("colors.navItemActive");
            }
        }

        &.nav-item-child {
            font-size: 90%;

            &:not(.iconOnly) {
                padding-left: 20px;
            }
        }

        .nav-item-title {
            flex: 1 0 auto;
            text-align: left;
            transition: transform 200ms ease-in-out;
            display: flex;
            align-items: center;
        }

        &:hover,
        &:active {
            .nav-item-title {
                transform: translateX(4px);
            }
        }
    }

    .nav-item-dropdown {
        height: 0;
        opacity: 1;
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }
}

.accordion-anim-enter-active,
.accordion-anim-leave-active {
    will-change: height, opacity;
    transition: height 0.2s ease, opacity 0.1s ease-in;
}

.accordion-anim-enter,
.accordion-anim-leave-to {
    opacity: 0;
    height: 0 !important;
}
</style>
